import ActionTransferToAgent from "./ActionTransferToAgent";
import Box from "@mui/material/Box";
import ActionWebhook from "./ActionWebhook";
import BookAMeeting from "./BookAMeeting";
const AssistantIntegration = ({
  handleSaveChangesUpdate,
  inititalWebhookUrls,
  setInititalWebhookUrls,
  phoneNumbers1,
  setPhoneNumbers1,
  initialBookAMeetingTemplate,
  setInititalBookAMeetingTemplate,
  saveChanges,
  setSaveChanges,
  handleSaveChanges,
  allActionData,
  setAllActionData,
  handleChange,
  isChanged,
  assistant,
  actionIds,
  setActionIds,
}) => {
  return (
    <Box component="section">
      <ActionTransferToAgent
        handleSaveChangesUpdate={handleSaveChangesUpdate}
        actionIds={actionIds}
        setActionIds={setActionIds}
        phoneNumbers1={phoneNumbers1}
        setPhoneNumbers1={setPhoneNumbers1}
        saveChanges={saveChanges}
        setSaveChanges={setSaveChanges}
        handleSaveChanges={handleSaveChanges}
        allActionData={allActionData}
        handleChange={handleChange}
        isChanged={isChanged}
        assistant={assistant}
      />
      <ActionWebhook
        handleSaveChangesUpdate={handleSaveChangesUpdate}
        actionIds={actionIds}
        setActionIds={setActionIds}
        inititalWebhookUrls={inititalWebhookUrls}
        setInititalWebhookUrls={setInititalWebhookUrls}
        saveChanges={saveChanges}
        setSaveChanges={setSaveChanges}
        handleSaveChanges={handleSaveChanges}
        allActionData={allActionData}
        setAllActionData={setAllActionData}
        handleChange={handleChange}
        isChanged={isChanged}
        assistant={assistant}
      />
      <BookAMeeting
        handleSaveChangesUpdate={handleSaveChangesUpdate}
        actionIds={actionIds}
        setActionIds={setActionIds}
        initialBookAMeetingTemplate={initialBookAMeetingTemplate}
        setInititalBookAMeetingTemplate={setInititalBookAMeetingTemplate}
        saveChanges={saveChanges}
        setSaveChanges={setSaveChanges}
        handleSaveChanges={handleSaveChanges}
        allActionData={allActionData}
        setAllActionData={setAllActionData}
        handleChange={handleChange}
        isChanged={isChanged}
        assistant={assistant}
      />
    </Box>
  );
};

export default AssistantIntegration;
