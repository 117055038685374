import React, { useEffect, useState } from "react";
import {
  Grid,
  Typography,
  Box,
  Switch,
  useTheme,
  IconButton,
  Button,
  MenuItem,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Tooltip,
  Card,
} from "@mui/material";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import FormControl from "@mui/material/FormControl";
import DeleteIcon from "@mui/icons-material/Delete";
import { useDispatch, useSelector } from "react-redux";
import {
  postCreateAction,
  deleteAction,
  updateAction,
} from "../../../../data/store/assistantsSlice";
import { log } from "../../../../data/configs/utils";
import Select from "@mui/material/Select";
import CloseIcon from "@mui/icons-material/Close";
import AddIcon from "@mui/icons-material/Add";
import CustomHintPopover from "../../../CustomHintPopover";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";

const ActionTransferToAgent = ({
  handleSaveChangesUpdate,
  allActionData,
  handleChange,
  isChanged,
  assistant,
  saveChanges,
  setSaveChanges,
  handleSaveChanges,
  phoneNumbers1,
  setPhoneNumbers1,
  actionIds,
  setActionIds,
}) => {
  const [phoneNumbers, setPhoneNumbers] = useState([]);
  const [TTAActionData, setTTAActionData] = useState([]);
  const [deletingPhoneNumbers, setDeletingPhoneNumbers] = useState([]);
  const [selectedNumber, setSelectedNumber] = useState(null);
  const [openAddNumberDialog, setOpenAddNumberDialog] = useState(false);
  const userQuota = useSelector((state) => state.authDetails?.user?.userQuota);
  const [showInput, setShowInput] = useState(false);
  const [isDeleteFromAllDialogOpen, setIsDeleteFromAllDialogOpen] =
    useState(false);
  const [newNumber, setNewNumber] = useState("");
  const theme = useTheme();
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const [assistantTransferToAgentValue, setAssistantTransferToAgentValue] =
    useState(assistant?.transferToAgent ?? false);
  const [selectedOpen, setSelectedOpen] = useState(false);
  const [agentQuota, setAgentQuota] = useState(
    userQuota?.find((quota) => quota.label === "Agents")?.remaining ?? 0
  );

  const handleExtractPhoneNumbersAndActions = () => {
    const actions = assistant?.actions || [];

    actions.forEach(({ data, _id }) => {
      if (data?.phoneNumbers) {
        setPhoneNumbers1((prevState) =>
          prevState.some(
            (phoneEntry) => phoneEntry.phoneNumber === data.phoneNumbers.join()
          )
            ? prevState
            : [...prevState, { phoneNumber: data.phoneNumbers.join(), id: _id }]
        );
      }

      if (data) {
        setActionIds((prevActionIds) =>
          prevActionIds.includes(_id) ? prevActionIds : [...prevActionIds, _id]
        );
      }
    });
  };

  const handleAddNumber = () => {
    const sanitizedNumber = newNumber?.replace(/[^0-9]/g, "");
    if (sanitizedNumber.trim() !== "") {
      const updatedPhoneNumbers = [...phoneNumbers, sanitizedNumber];
      setPhoneNumbers(updatedPhoneNumbers);
      setNewNumber("");
      if (assistant?.transferToAgent === false)
        handleChange({ transferToAgent: true });
      createAction([sanitizedNumber]);
    }
    setShowInput(false);
    setOpenAddNumberDialog(false);
  };

  const handleAddExistingNumber = (number, id) => {
    if (assistant?.transferToAgent === false)
      handleChange({ transferToAgent: true });
    setPhoneNumbers1((prevState) => [
      ...prevState,
      {
        phoneNumber: number,
        id: id,
      },
    ]);
    actionIds.push(id);
    handleChange({ actions: actionIds });
    setOpen(false);
    handleFilterAllActionData();
    handleSaveChangesUpdate("transferToAgent", true);
  };

  useEffect(() => {
    if (saveChanges?.transferToAgent) {
      handleSaveChanges();
      handleSaveChangesUpdate("transferToAgent", false);
    }
  }, [saveChanges]);

  const handleAddExistingNumberDialogBox = (number, id) => {
    setSelectedNumber({
      phoneNumber: number,
      id: id,
    });
    setSelectedOpen(true);
  };

  const handleFilterAllActionData = () => {
    const filteredData = allActionData?.filter(
      (item) => !actionIds?.includes(item._id)
    );
    setTTAActionData(filteredData);
  };

  const handleDeleteNumberDialog = (obj) => {
    setOpen(true);
    setDeletingPhoneNumbers({
      phoneNumber: obj?.phoneNumber,
      id: obj?.id,
    });
  };

  const handleDeleteNumber = (id, save = true) => {
    const updatedNumbers = phoneNumbers1.filter((obj) => obj.id !== id);
    setPhoneNumbers1(updatedNumbers);
    const UpdateActionIds = actionIds.filter((actionId) => actionId !== id);
    setActionIds(UpdateActionIds);
    handleChange({ actions: UpdateActionIds });
    setDeletingPhoneNumbers([]);
    if (save) {
      handleSaveChangesUpdate("transferToAgent", true);
    }
    if (updatedNumbers?.length === 0) {
      handleChange({ transferToAgent: false });
      setAssistantTransferToAgentValue(false);
      setShowInput(false);
    }
    return updatedNumbers;
  };

  const createAction = (number) => {
    dispatch(
      postCreateAction({
        type: "transfer_to_agent",
        data: {
          phoneNumbers: number,
        },
        triggerPrompts: [],
        name: "Transfer to agents",
        description: `"Transfer to ${assistant?.name}'s Phone Number"`,
      })
    )
      .unwrap()
      .then((res) => {
        if (res && res.data && res.data.action && res.data.action._id) {
          actionIds.push(res.data.action._id);
          handleChange({ actions: actionIds });
          setPhoneNumbers1((prevState) => [
            ...prevState,
            {
              phoneNumber: res.data.action.data.phoneNumbers?.join(),
              id: res.data.action._id,
            },
          ]);
        }
        if (res?.status === "success") {
          setAgentQuota((prev) => prev - 1);
        }
        handleSaveChangesUpdate("transferToAgent", true);
      })
      .catch((err) => {
        log("ERR-getAllActions", err);
      });
  };

  const handleDeleteAction = (id) => {
    dispatch(deleteAction({ id }))
      .unwrap()
      .then((res) => {
        if (res?.status === "success") {
          setAgentQuota((prev) => prev + 1);
        }
        const updatedNumbers = handleDeleteNumber(
          deletingPhoneNumbers.id,
          false
        );
        if (updatedNumbers?.length === 0) {
          handleChange({ transferToAgent: false });
          setAssistantTransferToAgentValue(false);
          setShowInput(false);
        }
        handleSaveChangesUpdate("transferToAgent", true);
      })
      .catch((err) => {
        log("ERR-getAllActions", err);
      });
  };

  const handleTransferToAgentToggle = (value) => {
    if (
      phoneNumbers1?.length > 0 &&
      value === true &&
      assistant?.transferToAgent !== true
    )
      handleChange({ transferToAgent: value });
    else if (value === false && assistant?.transferToAgent !== false) {
      handleChange({ transferToAgent: value });
    }
    setAssistantTransferToAgentValue(value);
  };

  useEffect(() => {
    handleExtractPhoneNumbersAndActions();
    handleFilterAllActionData();
  }, []);

  useEffect(() => {
    handleFilterAllActionData();
  }, [phoneNumbers1]);

  const handleToggleDialog = () => {
    setOpen(!open);
  };

  const handleAddNumberDialogClose = () => {
    setOpenAddNumberDialog(false);
    setShowInput(false);
  };
  return (
    <Grid container xs={12} justifyContent="center" marginTop={1}>
      <Accordion
        defaultExpanded
        expanded={assistantTransferToAgentValue}
        style={{
          width: "100%",
          borderRadius: 24,
          boxShadow: "0px 0px 1px 0px",
        }}
      >
        <AccordionSummary>
          <Grid
            xs={12}
            item
            container
            sx={{ height: "60px" }}
            alignItems="center"
            border={0}
            borderRadius={1}
            borderColor={theme.palette.divider}
            paddingInline={2}
          >
            <Grid item xs={6}>
              <Typography display="flex" variant="h6" alignItems="center">
                Transfer to Human
                <CustomHintPopover
                  size="small"
                  maxWidth={400}
                  // hintTitle={'Bot Temperature Setting'}
                  transformHorizontalPosition={"left"}
                  transformVerticalPosition={"center"}
                  anchorHorizontalPosition={"right"}
                  anchorVerticalPosition={"top"}
                  hintContent={
                    "During call if the contact wishes to talk to human, we will forward the call to these number(s). The call will be tried with all the number(s) but only connected with the first agent who picks up the call."
                  }
                />
              </Typography>
            </Grid>
            <Grid item xs={6} container justifyContent="flex-end">
              <Switch
                defaultChecked={assistant?.transferToAgent}
                checked={assistantTransferToAgentValue}
                inputProps={{ "aria-label": "controlled" }}
                onChange={(event) => {
                  handleTransferToAgentToggle(event.target.checked);
                }}
              />
            </Grid>
          </Grid>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container xs={12}>
            <Grid xs={12} item container paddingInline={4}>
              {phoneNumbers1?.length > 0 ? (
                <Grid
                  container
                  spacing={2}
                  xs={12}
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    marginBottom: 2,
                  }}
                >
                  {phoneNumbers1?.flatMap((obj) => (
                    <Grid item xs={3}>
                      <Card
                        sx={{
                          padding: 1,
                          borderRadius: "10px",
                          bgcolor: "#d8e7ff",
                        }}
                      >
                        <Grid container xs={12}>
                          <Grid
                            item
                            xs={10}
                            sx={{
                              display: "flex",
                              justifyContent: "flex-start",
                              alignItems: "center",
                            }}
                          >
                            <Typography
                              sx={{ fontSize: 20, paddingInline: 1 }}
                              color="text.primary"
                            >
                              +{obj.phoneNumber}
                            </Typography>
                          </Grid>
                          <Grid
                            item
                            xs={2}
                            sx={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                          >
                            <IconButton
                              onClick={() => handleDeleteNumberDialog(obj)}
                            >
                              <DeleteIcon />
                            </IconButton>
                          </Grid>
                        </Grid>
                      </Card>
                    </Grid>
                  ))}
                </Grid>
              ) : null}

              <Grid item xs={12} align="start" marginTop={-2}>
                <Tooltip
                  title={
                    agentQuota > 0
                      ? ""
                      : "Upgrade your plan to add more numbers"
                  }
                  arrow
                  placement="top"
                >
                  <span>
                    <Button
                      onClick={() => setOpenAddNumberDialog(true)}
                      variant="contained"
                      startIcon={<AddIcon />}
                      sx={{
                        width: "200px",
                        marginBlock: 4,
                        borderRadius: "10px !important",
                      }}
                    >
                      Add Number
                    </Button>
                  </span>
                </Tooltip>
              </Grid>
            </Grid>
          </Grid>
        </AccordionDetails>

        {/* </Grid>
                </Card> */}
      </Accordion>
      <Dialog
        PaperProps={{
          sx: {
            overflowY: "visible",
          },
        }}
        open={openAddNumberDialog}
        onClose={handleAddNumberDialogClose}
      >
        <DialogTitle>
          <Typography align="center" variant="h6" fontWeight="bold">
            Add Number
          </Typography>
          <IconButton
            aria-label="close"
            onClick={handleAddNumberDialogClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent sx={{ overflowY: "visible" }}>
          <Box
            mt={2}
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              minWidth: "550px !important",
            }}
          >
            <Grid container xs={8}>
              {!showInput ? (
                <Grid item xs={12} mb={4} sx={{ minWidth: "100%" }}>
                  <Button
                    style={{ minWidth: "200px", paddingBlock: 12 }}
                    variant="contained"
                    onClick={() => {
                      setShowInput(true);
                    }}
                    fullWidth
                    disabled={agentQuota < 1}
                    startIcon={<AddIcon />}
                  >
                    <Typography variant="body2" textTransform="none">
                      Add New Number
                    </Typography>
                  </Button>
                </Grid>
              ) : (
                <Grid
                  container
                  xs={12}
                  marginTop={2}
                  sx={{ display: "flex", justifyContent: "flex-end" }}
                  mb={4}
                >
                  <Grid item xs={12}>
                    <PhoneInput
                      placeholder="Enter your phone number"
                      enableSearch
                      disableSearchIcon
                      country={"us"}
                      variant="outlined"
                      value={newNumber}
                      onChange={(value) => setNewNumber(value)}
                      size="small"
                      containerStyle={{
                        fontSize: "20px",
                        marginBottom: "10px",
                      }}
                      inputStyle={{
                        width: "100%",
                        borderRadius: "24px",
                        minHeight: "38px",
                        fontSize: "initial",
                      }}
                      dropdownStyle={{
                        color:'black',
                        alignItems: "center",
                        width: "1000%",
                        fontSize: "small",
                      }}
                      inputProps={{
                        required: true,
                        autoFocus: true,
                      }}
                    />
                  </Grid>
                  <Grid
                    container
                    xs={12}
                    mt={2}
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <Button
                      disabled={newNumber?.length < 8}
                      onClick={handleAddNumber}
                      variant="contained"
                      sx={{ minWidth: "100px", margin: 1 }}
                    >
                      Add
                    </Button>
                    <Button
                      onClick={() => setShowInput(false)}
                      variant="contained"
                      sx={{ minWidth: "100px", margin: 1 }}
                    >
                      Close
                    </Button>
                  </Grid>
                </Grid>
              )}
              <Grid
                item
                xs={12}
                sx={{ marginBottom: 2, justifyContent: "center" }}
              >
                {TTAActionData?.length !== 0 && (
                  <FormControl style={{ width: "100%" }}>
                    <Select
                      value=""
                      displayEmpty
                      sx={{
                        "& .MuiOutlinedInput-input": {
                          paddingBlock: 1,
                        },
                        justifyContent: "center",
                      }}
                      fullWidth
                    >
                      <MenuItem
                        value=""
                        style={{ display: "flex", justifyContent: "center" }}
                      >
                        <em>Select Existing Numbers</em>
                      </MenuItem>
                      {TTAActionData?.filter(
                        (item) => item?.type === "transfer_to_agent"
                      )?.flatMap((item) => (
                        <MenuItem
                          key={item._id}
                          value={item._id}
                          onClick={() =>
                            handleAddExistingNumberDialogBox(
                              item.data.phoneNumbers,
                              item._id
                            )
                          }
                        >
                          {item?.data?.phoneNumbers}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                )}
              </Grid>
            </Grid>
          </Box>
        </DialogContent>
      </Dialog>
      <Dialog open={open} onClose={() => setOpen(false)}>
        <DialogTitle>
          <Typography align="center" variant="h6" fontWeight="bold">
            Delete Phone Number
          </Typography>

          <IconButton
            aria-label="close"
            onClick={handleToggleDialog}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <Typography variant="body1" mb={1}>
            Are you sure you want to delete this phone number
            {deletingPhoneNumbers.phoneNumber}?
          </Typography>
          <Box mt={2}>
            <Grid container spacing={2} mb={2} mt={1}>
              <Grid item xs={6}>
                <Tooltip
                  title={`This action will delete the phone number ${deletingPhoneNumbers.phoneNumber} from the current assistant`}
                >
                  <Button
                    style={{ minWidth: "200px", paddingBlock: 12 }}
                    variant="contained"
                    onClick={() => {
                      handleDeleteNumber(deletingPhoneNumbers.id);
                      handleToggleDialog();
                    }}
                    fullWidth
                    endIcon={<DeleteIcon />}
                  >
                    <Typography variant="body2" textTransform="none">
                      From Current Assistant
                    </Typography>
                  </Button>
                </Tooltip>
              </Grid>
              <Grid item xs={6}>
                <Tooltip
                  title={`This action will delete the phone number ${deletingPhoneNumbers.phoneNumber} from the all assistant`}
                >
                  <Button
                    style={{ minWidth: "200px", paddingBlock: 12 }}
                    variant="contained"
                    onClick={() => {
                      handleToggleDialog();
                      setIsDeleteFromAllDialogOpen(true);
                    }}
                    color="error"
                    fullWidth
                    endIcon={<DeleteIcon />}
                  >
                    <Typography variant="body2" textTransform="none">
                      From All Assistants
                    </Typography>
                  </Button>
                </Tooltip>
              </Grid>
            </Grid>
          </Box>
        </DialogContent>
      </Dialog>
      <Dialog
        open={isDeleteFromAllDialogOpen}
        onClose={() => setIsDeleteFromAllDialogOpen(false)}
      >
        <DialogTitle>
          <Typography align="center" variant="h6" fontWeight="bold">
            Delete Phone Number From All Assistants
          </Typography>
          <IconButton
            aria-label="close"
            onClick={() => setIsDeleteFromAllDialogOpen(false)}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <Grid item xs={12} align="center">
            <Typography variant="body1">
              Are you sure you want to delete this phone number
              {deletingPhoneNumbers.phoneNumber} from all assistants?
            </Typography>
          </Grid>
          <Box mt={2}>
            <Grid item xs={12} align="center">
              <Button
                style={{
                  minWidth: "200px",
                  paddingBlock: 12,
                  paddingInline: 15,
                }}
                variant="contained"
                onClick={() => {
                  handleDeleteAction(deletingPhoneNumbers?.id);
                  setIsDeleteFromAllDialogOpen(false);
                }}
                color="error"
                endIcon={<DeleteIcon />}
              >
                <Typography align="center" variant="body1" textTransform="none">
                  Confirm Delete
                </Typography>
              </Button>
            </Grid>
            <Grid item xs={12} align="center" mt={2}>
              <Typography variant="caption">
                This action will remove this {deletingPhoneNumbers.phoneNumber}
                number from all the assistant you have.
              </Typography>
            </Grid>
          </Box>
        </DialogContent>
      </Dialog>
      <Grid container spacing={2} justifyContent="center">
        <Grid item xs={12} sm={8} md={6}>
          <Dialog open={selectedOpen} onClose={() => setSelectedOpen(false)}>
            <DialogTitle align="center">Confirm Number Addition</DialogTitle>
            <DialogContent>
              <DialogContentText sx={{ color: "#444" }}>
                Are you sure you want to add the number
                <strong>{selectedNumber?.phoneNumber}</strong> to the
                <strong>{assistant?.name ?? "existing"}</strong> assistant?
              </DialogContentText>
            </DialogContent>
            <DialogActions sx={{ paddingRight: 2 }}>
              <Button onClick={() => setSelectedOpen(false)} color="primary">
                Cancel
              </Button>
              <Button
                onClick={() => {
                  handleAddExistingNumber(
                    selectedNumber.phoneNumber,
                    selectedNumber.id
                  );
                  setSelectedOpen(false);
                  handleAddNumberDialogClose();
                }}
                color="primary"
              >
                Add Number
              </Button>
            </DialogActions>
          </Dialog>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default ActionTransferToAgent;
