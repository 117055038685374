import React, { useEffect, useState } from "react";
import {
  Grid,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormLabel,
  Typography,
} from "@mui/material";
import { languageModelMap } from "../../../../data/configs/constants";

const BaseModelMenu = ({
  updatedData,
  handleChange,
  setUpdatedData,
  selectedLanguage,
  setIsChanged,
}) => {
  const [selectedModel, setSelectedModel] = useState("");

  // Combine both high_quality_base_model_ids and the rest of the model IDs if they exist
  const combinedModelIds = updatedData?.high_quality_base_model_ids || [];

  useEffect(() => {
    if (updatedData?.tts_provider === "elevenlabs") {
      const modelToSet = updatedData?.model
        ? updatedData.model
        : combinedModelIds?.includes("eleven_multilingual_v2")
        ? "eleven_multilingual_v2"
        : "";

      setSelectedModel(modelToSet);

      if (!updatedData?.model) {
        if (updatedData?.category === "generated") return setIsChanged(false);

        setUpdatedData((prevData) => ({
          ...prevData,
          model: modelToSet,
        }));
        handleChange({ ssml_data: { ...updatedData, model: modelToSet } });
      }
    }
  }, [updatedData, combinedModelIds]);

  const handleChangeModal = (event) => {
    const selectedModel = event.target.value;
    setSelectedModel(selectedModel);
    const newUpdatedData = {
      ...updatedData, // Spread previous data
      model: selectedModel, // Add the new model with its value
    };
    setUpdatedData(newUpdatedData);
    handleChange({ ssml_data: newUpdatedData });
  };

  // Filter models based on language compatibility
  const allModelIds = Object.keys(languageModelMap);
  const filteredModelIds = allModelIds.filter((modelId) => {
    const supportedLanguages = languageModelMap[modelId] || [];
    return supportedLanguages.includes(selectedLanguage);
  });

  return updatedData?.tts_provider === "elevenlabs" ? (
    <Grid item xs={12} marginBlock={2}>
      <FormControl fullWidth>
        <FormLabel sx={{ ml: 1.7, mb: -1 }}>
          <Typography variant="caption">Choose Model</Typography>
        </FormLabel>
        <Select
          size="small"
          label="Choose Model"
          labelId="base-model-select-label"
          id="base-model-select"
          value={selectedModel}
          onChange={handleChangeModal}
        >
          {selectedModel === "" && (
            <MenuItem value="" disabled>
              Choose one
            </MenuItem>
          )}
          {filteredModelIds.length > 0 ? (
            filteredModelIds.map((modelId) => (
              <MenuItem
                key={modelId}
                value={modelId}
                disabled={!filteredModelIds.includes(modelId)}
              >
                {modelId}
              </MenuItem>
            ))
          ) : (
            <MenuItem value="" disabled>
              No models available
            </MenuItem>
          )}
        </Select>
      </FormControl>
      {!updatedData.model?.trim() && (
        <Typography paddingInline={2} variant="subtitle2" color="error">
          Model is required !
        </Typography>
      )}
    </Grid>
  ) : null;
};

export default BaseModelMenu;
