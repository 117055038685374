import React, { useRef } from "react";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import VariableInputEditor from "../../../MentionEditor";
import Box from "@mui/material/Box";
import CustomHintPopover from "../../../CustomHintPopover";
import { Alert } from "@mui/material";

const CallOpeningExpandingCard = ({
  comCorrection,
  setComCorrection,
  handleChange,
  handleExpandClick,
  mentionList,
  assistant,
}) => {
  const editorRef = useRef(null);

  return (
    <Card sx={{ width: "100%" }}>
      <CardActions disableSpacing>
        <Grid container>
          <Grid item xs={12}>
            <Box
              sx={{
                width: "100%",
                display: "flex",
                alignItems: "center",
                gap: 2,
                padding: 1,
              }}
              onClick={handleExpandClick}
            >
              <Grid container xs={12}>
                <Grid item xs={12} marginBottom={1}>
                  <Typography
                    sx={{ display: "flex" }}
                    align="start"
                    variant="h6"
                  >
                    Call Opening Message
                    <CustomHintPopover
                      size="small"
                      maxWidth={400}
                      // hintTitle={'Bot Temperature Setting'}
                      transformHorizontalPosition={"left"}
                      transformVerticalPosition={"center"}
                      anchorHorizontalPosition={"right"}
                      anchorVerticalPosition={"top"}
                      hintContent={
                        <>
                          <p>
                            This is the first message that your AI Assistant
                            will say once the call is picked up.
                          </p>
                          <br />
                          <p>
                            <strong>Additional Note :</strong> Make use of
                            variables to personalise it for each contact. (type
                            @ in the text box to use it)
                          </p>
                        </>
                      }
                    />
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Box sx={{ width: "100%", marginTop: "-10px" }}>
                    <VariableInputEditor
                      style={{
                        minHeight: "100%",
                        minWidth: "100%",
                      }}
                      formattedEnvVariables={mentionList}
                      placeholder="eg: Hi, How are you doing? Do you have a minute to talk?"
                      value={assistant?.callOpeningMessage}
                      onTextChange={(htmlText) =>
                        handleChange({ callOpeningMessage: htmlText })
                      }
                      editorRef={editorRef}
                      wordLimit={200}
                    />
                    {comCorrection.comWarnActive ? (
                      <Typography
                        paddingInline={2}
                        variant="subtitle2"
                        color="error"
                      >
                        {comCorrection.msg}
                      </Typography>
                    ) : null}
                    {(assistant.callOpeningMessage?.trim() === "<p><br></p>" ||
                      !assistant.callOpeningMessage?.trim()) && (
                      <Typography
                        paddingInline={2}
                        variant="subtitle2"
                        color="error"
                      >
                        Assistant's Call Opening Message is required !
                      </Typography>
                    )}
                  </Box>
                </Grid>
              </Grid>
            </Box>
          </Grid>
        </Grid>
      </CardActions>
    </Card>
  );
};

export default CallOpeningExpandingCard;
