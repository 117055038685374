import React, { useEffect, useRef, useState } from "react";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import InputLabel from "@mui/material/InputLabel";
import PropTypes from "prop-types";
import { Button, Chip, Grid, IconButton, Typography } from "@mui/material";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import PauseIcon from "@mui/icons-material/Pause";
import CustomHintPopover from "../../../CustomHintPopover";
import RefreshIcon from "@mui/icons-material/Refresh";
import { COUNTRY_NAME_BASED_ON_CODE } from "../../../../data/configs/constants";
const VoiceAssistant = ({
  updatedData,
  handleInputChange,
  LANG_CODES,
  gender,
  getAudioUrl,
  selectedLanguage,
  selectedGender,
  filteredVoices,
  handleSelectedAccent,
  handleGetElevenLabGeneratedVoices,
}) => {
  const [isPlaying, setIsPlaying] = useState(null);
  const audioRefs = useRef({});

  const playPauseHandler = (item) => {
    const audioRef = audioRefs.current[item.label];
    if (isPlaying === item.label) {
      audioRef.pause();
      setIsPlaying(null);
    } else {
      audioRef.src =
        item?.tts_provider === "elevenlabs"
          ? getAudioUrl(item.lang, item.label, item.voice_recording_id, true)
          : getAudioUrl(item.lang, item.label);
      audioRef.play();
      setIsPlaying(item.label);
    }
  };

  const handleAudioEnded = (item) => {
    setIsPlaying(null);
  };

  return (
    <Grid container display="flex" justifyContent="space-between">
      <Grid item xs={4} display="flex" alignItems="center" pl={1}>
        <Typography display="flex" alignItems="flex-end" variant="subtitle1">
          Choose Accent :
          <CustomHintPopover
            size="small"
            maxWidth={400}
            transformHorizontalPosition={"left"}
            transformVerticalPosition={"center"}
            anchorHorizontalPosition={"right"}
            anchorVerticalPosition={"top"}
            hintContent={"Choose accent based on your requirements."}
          />
        </Typography>
      </Grid>
      {/* <Grid item xs={8}>
        <FormControl sx={{ mt: 0.1 }} fullWidth>
          <InputLabel id="choose-accent-label">Choose Accent</InputLabel>
          <Select
            labelId="choose-accent-label"
            label="Choose Accent"
            value={handleSelectedAccent()}
            onChange={(e) => {
              e.stopPropagation();
              handleInputChange(e.target.value);
            }}
            MenuProps={{
              sx: { maxHeight: 400 },
            }}
            displayEmpty
            required
            sx={{
              "& .MuiOutlinedInput-notchedOutline": {
                borderColor: !handleSelectedAccent() && "#FF0000",
              },
            }}
          >
            {filteredVoices?.length > 0 &&
              filteredVoices?.map((item) => (
                <MenuItem
                  key={`${item.label}|${item.tts_provider}`}
                  value={`${item.label}|${item.tts_provider}`}
                >
                  <Grid container display="flex" justifyContent="space-between">
                    <Grid
                      item
                      xs={5}
                      sx={{ display: "grid", alignItems: "center" }}
                    >
                      <Typography variant="body1">{item.label}</Typography>
                    </Grid>
                    <Grid
                      item
                      xs={2}
                      sx={{ display: "grid", alignItems: "center" }}
                      align="center"
                    >
                      <Typography>
                        {COUNTRY_NAME_BASED_ON_CODE[item.country] ??
                          item.country}
                      </Typography>
                    </Grid>
                    <Grid
                      item
                      xs={2}
                      sx={{
                        paddingInline: 2,
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                      align="center"
                    >
                      {item?.tts_provider === "elevenlabs" ? (
                        <IconButton>
                          <Chip size="small" label="EL" color="warning" />
                        </IconButton>
                      ) : (
                        <IconButton>
                          <Chip size="small" label="VG" color="success" />
                        </IconButton>
                      )}
                    </Grid>

                    <Grid item xs={2} align="center">
                      <IconButton
                        onClick={(e) => {
                          e.stopPropagation();
                          playPauseHandler(item);
                        }}
                        style={{ cursor: "pointer", margin: "-10px" }}
                      >
                        {isPlaying === item.label ? (
                          <PauseIcon fontSize="small" />
                        ) : (
                          <PlayArrowIcon fontSize="small" />
                        )}
                      </IconButton>
                      <audio
                        ref={(ref) => {
                          if (ref) {
                            audioRefs.current[item.label] = ref;
                            ref.addEventListener("ended", () =>
                              handleAudioEnded(item)
                            );
                          }
                        }}
                      />
                    </Grid>
                  </Grid>
                </MenuItem>
              ))}
           
          </Select>
        </FormControl>
      </Grid> */}
      <Grid item xs={12}>
        <FormControl sx={{ mt: 0.1 }} fullWidth>
          <Select
            size="small"
            labelId="choose-accent-label"
            value={handleSelectedAccent()}
            onChange={(e) => {
              const selectedValue = e.target.value;
              if (selectedValue !== "fetch-elevenlabs-voices") {
                handleInputChange(selectedValue); // Handle normal selection
              }
            }}
            MenuProps={{
              sx: { maxHeight: 400 },
            }}
            displayEmpty
            required
            sx={{
              position: "relative",
              "& .MuiOutlinedInput-notchedOutline": {
                borderColor: !handleSelectedAccent() && "#FF0000",
              },
            }}
            renderValue={(selected) => {
              if (!selected) {
                return <em>Choose Accent</em>;
              }

              const selectedItem = filteredVoices.find(
                (item) => `${item.label}|${item.tts_provider}` === selected
              );
              return (
                <Grid container justifyContent="space-between">
                  <Grid
                    item
                    xs={3}
                    sx={{ display: "grid", alignItems: "center" }}
                  >
                    <Typography variant="body1">
                      {selectedItem.label}
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    xs={2}
                    sx={{
                      paddingInline: 2,
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                    align="center"
                  >
                    <IconButton size="small">
                      <Chip
                        size="small"
                        label={
                          selectedItem.tts_provider === "elevenlabs"
                            ? "EL"
                            : "VG"
                        }
                        color={
                          selectedItem.tts_provider === "elevenlabs"
                            ? "warning"
                            : "success"
                        }
                      />
                    </IconButton>
                  </Grid>
                  <Grid
                    item
                    xs={3}
                    sx={{
                      paddingInline: 2,
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                    align="center"
                  >
                    {selectedItem.tts_provider === "elevenlabs" && (
                      <IconButton size="small">
                        <Chip
                          size="small"
                          label={
                            selectedItem.category === "generated"
                              ? "Generated"
                              : "Pre Made"
                          }
                          color={
                            selectedItem.category === "generated"
                              ? "warning"
                              : "success"
                          }
                        />
                      </IconButton>
                    )}
                  </Grid>
                </Grid>
              );
            }}
          >
            {filteredVoices?.length > 0 &&
              filteredVoices?.map((item) => (
                <MenuItem
                  key={`${item.label}|${item.tts_provider}`}
                  value={`${item.label}|${item.tts_provider}`}
                >
                  <Grid
                    item
                    xs={12}
                    container
                    display="flex"
                    justifyContent="space-between"
                  >
                    <Grid
                      item
                      xs={3}
                      sx={{ display: "grid", alignItems: "center" }}
                    >
                      <Typography variant="body1">{item.label}</Typography>
                    </Grid>
                    <Grid
                      item
                      xs={2}
                      sx={{
                        paddingInline: 2,
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                      align="center"
                    >
                      {item?.tts_provider === "elevenlabs" ? (
                        <IconButton size="small">
                          <Chip size="small" label="EL" color="warning" />
                        </IconButton>
                      ) : (
                        <IconButton size="small">
                          <Chip size="small" label="VG" color="success" />
                        </IconButton>
                      )}
                    </Grid>
                    <Grid
                      item
                      xs={3}
                      sx={{
                        paddingInline: 2,
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                      align="center"
                    >
                      {item?.tts_provider === "elevenlabs" ? (
                        item?.category === "generated" ? (
                          <IconButton size="small">
                            <Chip
                              size="small"
                              label="Generated"
                              color="warning"
                            />
                          </IconButton>
                        ) : (
                          <IconButton size="small">
                            <Chip
                              size="small"
                              label="Pre Made"
                              color="success"
                            />
                          </IconButton>
                        )
                      ) : null}
                    </Grid>

                    <Grid
                      item
                      xs={2}
                      display="flex"
                      alignItems="center"
                      justifyContent="center"
                      align="center"
                    >
                      {item?.category !== "generated" ? (
                        <>
                          <IconButton
                            size="small"
                            onClick={(e) => {
                              e.stopPropagation();
                              playPauseHandler(item);
                            }}
                            style={{ cursor: "pointer", margin: "-10px" }}
                          >
                            {isPlaying === item.label ? (
                              <PauseIcon fontSize="small" />
                            ) : (
                              <PlayArrowIcon fontSize="small" />
                            )}
                          </IconButton>
                          <audio
                            ref={(ref) => {
                              if (ref) {
                                audioRefs.current[item.label] = ref;
                                ref.addEventListener("ended", () =>
                                  handleAudioEnded(item)
                                );
                              }
                            }}
                          />
                        </>
                      ) : null}
                    </Grid>
                  </Grid>
                </MenuItem>
              ))}
            <MenuItem
              value="fetch-elevenlabs-voices"
              onClick={(e) => {
                e.stopPropagation(); // Prevent selection
                handleGetElevenLabGeneratedVoices(); // Trigger the API call
              }}
              sx={{
                position: "sticky",
                bottom: "0px",
                zIndex: 9999,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                fontWeight: "bold",
                backgroundColor: "background.paper",
                cursor: "pointer",
                "&:hover": {
                  backgroundColor: "#f0f0f0", // Same as the default background color to remove hover effect
                },
              }}
            >
              <Button variant="text" color="primary" endIcon={<RefreshIcon />}>
                Fetch Generated Voices
              </Button>
            </MenuItem>
          </Select>
        </FormControl>
      </Grid>

      {!handleSelectedAccent() && (
        <Grid item xs={12}>
          <Typography
            align="end"
            paddingInline={2}
            variant="subtitle2"
            color="error"
          >
            Choose Voice Accents !
          </Typography>
        </Grid>
      )}
    </Grid>
  );
};

VoiceAssistant.propTypes = {
  updatedData: PropTypes.object.isRequired,
  handleInputChange: PropTypes.func.isRequired,
  LANG_CODES: PropTypes.object.isRequired,
  gender: PropTypes.string.isRequired,
};

export default VoiceAssistant;
