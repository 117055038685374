import { useState, useCallback, useEffect, useRef } from "react";
import { GENERATE_ELEVENLAB_VOICES } from "../store/integrationSlice/api";

const useAudioStreamElevenLabsAudioSamples = (payload, token) => {
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [audioUrl, setAudioUrl] = useState(null); // Track the current audio URL
  const baseUrl = process.env.REACT_APP_BASE_URL;

  useEffect(() => {
    // Cleanup function to revoke the blob URL when the component unmounts or the URL changes
    return () => {
      if (audioUrl) {
        URL.revokeObjectURL(audioUrl);
      }
    };
  }, [audioUrl]);

  const fetchAudio = useCallback(async () => {
    if (!payload) return; // Exit if no payload is set

    setError(null);
    setLoading(true);

    try {
      const response = await fetch(`${baseUrl}${GENERATE_ELEVENLAB_VOICES}`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(payload),
      });

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      const blob = await response.blob();
      const url = URL.createObjectURL(blob);
      setAudioUrl(url); // Update the state with the new URL
    } catch (err) {
      console.error("Error fetching audio:", err);
      setError(err.message || "An error occurred");
    } finally {
      setLoading(false);
    }
  }, [baseUrl, payload, token]);

  return { audioUrl, error, loading, fetchAudio };
};

export default useAudioStreamElevenLabsAudioSamples;
