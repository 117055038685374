// import { installGoHighLevel, installHubSpot } from "../store/integrationSlice"
import GroupIcon from "@mui/icons-material/Group";
import TtyIcon from "@mui/icons-material/Tty";
import PermContactCalendarIcon from "@mui/icons-material/PermContactCalendar";
import CampaignIcon from "@mui/icons-material/Campaign";
import GroupOutlinedIcon from "@mui/icons-material/GroupOutlined";
import TtyOutlinedIcon from "@mui/icons-material/TtyOutlined";
import PermContactCalendarOutlinedIcon from "@mui/icons-material/PermContactCalendarOutlined";
import CampaignOutlinedIcon from "@mui/icons-material/CampaignOutlined";
import SupportAgentIcon from "@mui/icons-material/SupportAgent";
import HistoryIcon from "@mui/icons-material/History";
import ApiIcon from "@mui/icons-material/Api";
import AppsIcon from "@mui/icons-material/Apps";
import IMG_SIGNUP_1 from "../assets/img/signupCarousel1.png";
import IMG_SIGNUP_2 from "../assets/img/signupCarousel2.png";
import IMG_SIGNUP_3 from "../assets/img/signupCarousel3.png";
import IMG_SIGNUP_4 from "../assets/img/signupCarousel4.png";
import HUBSPOT_LOGO from "../assets/img/hubspot_logo.png";
import GOHIGHLEVEL_LOGO from "../assets/img/gohighlevel.png";
import { MenuItem } from "@mui/material";
import React from "react";

export const DEVELOPERSAPI_TEMPLATE = {
  initialCode: `curl --location 'https://core-saas.voicegenie.ai/api/v1/pushCallToCampaign' \
--header 'Content-Type: application/json' \
--data '{
    "token": "your_token_here", 
    "workspaceId": "your_workspaceId_here", 
    "campaignId": "YOUR_CAMPAIGNID_HERE", 
    "customerNumber": "+10909090909", 
    "customerInformation": { 
        "first_name" : "john",
        "last_name" : "wick"
    }
}'`,
  description: `This is an example cURL request that demonstrates adding a call to a recurring campaign.`,
  linkUrl: `https://voicegenie.gitbook.io/voicegenie-ai/product-guides/campaigns/types-of-campaign/recurring-campaign`,
  linkText: `[click here to know more about recurring campaigns]`,
};

export const DEVELOPERSAPI_DOCS_LINK = `https://voicegenie.gitbook.io/voicegenie-ai/developer-documentation-1.0`;

export const THEME_MODE = Object.freeze({
  LIGHT: "light",
  DARK: "dark",
});

export const THEME_VARIABLE = Object.freeze({
  APP_HEADER_HEIGHT: 64,
  APP_SIDER_COLLAPSE_WIDTH: 64,
  APP_SIDER_WIDTH: 250,
  ONBOARDING_HEADER_HEIGHT: 150,
});

export const BREAKPOINT_VALUES = Object.freeze({
  xs: 0,
  sm: 600,
  lg: 1200,
  md: 900,
  xl: 1536,
});

export const LOCAL_STORAGE = Object.freeze({
  APP_SUMO_CODE: "vg-app-sumo-code",
  GOHIGH_LEVEL_CODE: "vg-gohigh-code",
  APP_VERSION: "vg-app-version",
  APP_USER: "vg-app-user",
  AUTH_TOKEN: "vg-auth-token",
  THEME: "vg-theme-mode",
  SHOW_DEAL: "vg-show-deal",
  AUTH_SEARCH_PARAMS: "vg-auth-search-params",
  IS_NEW_USER: "vg-is-new-user",
});

export const ROUTE_PATH = Object.freeze({
  //---------------- AUTH ROUTES ----------------
  LOGIN: "/login",
  SIGNUP: "/signup",
  APPSUMO_SIGNUP: "/redeem/AppSumo",
  FORGOT_PASSWORD: "/forgot-password",
  RESET_PASSWORD: "/reset-password",
  VERIFY_GOOGLE_AUTH: "/verify-google-auth",
  VERIFY_EMAIL: "/verify-email",
  SIGNUP_PASSWORD: "/signup-password",

  //-------------- ROOT PAGES ROUTES -------------
  WELCOME: "/onboarding",
  MICRO_SURVEY: "/onboarding/survey",
  CREATE_FIRST_ASSISTANT: "/onboarding/createAssistant",
  UPDATE_AND_TEST_ASSISTANT: "/onboarding/updateAssistant",

  //---------------- APP ROOT ROUTES ----------------

  ASSISTANTS: "/",
  EDIT_ASSISTANT: "/editAssistant",
  PHONE_NUMBERS: "/phone-numbers",
  CONTACT_LIST: "/contact-list",
  EDIT_CONTACT: "/contact-list/:contactListId",
  SETTINGS: "/settings",
  CAMPAIGNS: "/campaigns",
  EDIT_CAMPAIGN: "/campaigns/:campaignId",
  KNOWLEDGE_BASES: "/knowledge-bases",
  CALL_HISTORY: "/callhistory",
  CALL_HISTORY_AND_CAMPAIGN_CALL_DETAIL: "/call-details",
  SUPPORT: "/support",
  DEVELOPERSAPI: "/developers-api",
  HUBSPOT_VERIFICATION: "/hubspot-verify",
  GOHIGHLEVEL_VERIFICATION: "/gohigh-verify",
  INTEGRATIONS: "/integrations",
  PRICING: "/pricing",
  BILLING: "/billing",
  THANK_YOU: "/thank-you",

  //---------------- APP SELECTED VOICE AGENT ROUTES ----------------
  VA_OVERVIEW: "",
});

export const APP_PAGES = Object.freeze({
  //---------------- AUTH PAGES ----------------
  LOGIN: "Login",
  SIGNUP: "Signup",
  APPSUMO_SIGNUP: "AppSumo Signup",
  FORGOT_PASSWORD: "Forgot Password",
  RESET_PASSWORD: "Reset Password",
  VERIFY_EMAIL: "Verify Email",
  INTEGRATION_VERIFICATION: "Verification",
  SIGNUP_PASSWORD: "Signup Password",
  THANK_YOU: "Thank You",

  //-------------- ROOT PAGES -------------
  WELCOME: "Welcome",
  MICROSURVEY: "Survey",
  CREATE_FIRST_ASSISTANT: "Create Assistant",

  //---------------- APP ROOT PAGES ----------------
  PHONE_NUMBERS: {
    PAGE: "Phone Numbers",
    DESCRIPTION:
      "Acquire a Dialer ( Twilio ) Phone Number to connect with your users. This will facilitate your outbound calls, ensuring seamless communication.",
  },
  SETTINGS: {
    PAGE: "Settings",
    DESCRIPTION: "",
  },
  CAMPAIGNS: {
    PAGE: "Campaigns",
    DESCRIPTION:
      "Campaigns are where you bring everything together including Assistant, Phone Number and Contact List. Using Campaigns you reach your contacts and gather post call insights.",
  },
  CONTACT_LIST: {
    PAGE: "Contact List",
    DESCRIPTION:
      "Simplify audience segmentation by uploading your contact lists. This empowers you to categorize & target your customers effectively.",
  },
  ASSISTANTS: {
    PAGE: "Assistants",
    DESCRIPTION:
      "Assistant is the Generative AI agent that talks to your contacts. It follows it's the assigned script and goal. It can be customised as per the needs ( like the language in which it communicates accent, etc ).",
  },
  KNOWLEDGE_BASES: {
    PAGE: "Knowledge Bases",
    DESCRIPTION:
      "Enrich your bot's capacity to respond to your audience's queries by providing training data & scripts. Customize your knowledge base, and effortlessly connect it to your assistant.",
  },
  CALL_HISTORY: {
    PAGE: "Call History",
    DESCRIPTION:
      "Track and review the history of all calls made through your system. Gain insights into call volume, duration, and outcomes to optimize your communication strategies.",
  },
  CALL_HISTORY_AND_CAMPAIGNS_CALL_DETAIL: {
    PAGE: "Call Details",
    DESCRIPTION:
      "Access detailed information about each individual call, including timestamps, caller information, and call outcomes. Use this data to fine-tune your campaigns and improve engagement with your contacts.",
  },
  DEVELOPERSAPI: {
    PAGE: "Developer APIs",
    DESCRIPTION:
      "Developer APIs provide access to VoiceGenie Functionalities. For example you can add contacts to Recurring campaigns over an API. Users need to generate developer credentials to access these functionalities.",
  },
  SUPPORT: {
    PAGE: "Support",
    DESCRIPTION:
      "Whenever you require assistance, we're here to help. Access the support you need.",
  },
  INTEGRATIONS: {
    PAGE: "Integrations",
    DESCRIPTION:
      "Integrate apps to supercharge VoiceGenie. Connect different tools and services to streamline your workflow and maximize efficiency.",
  },

  PRICING: {
    PAGE: "Pricing",
    DESCRIPTION:
      "Explore our range of plans, each designed to meet your specific business requirements. Choose the one that perfectly aligns with your needs.",
  },
  BILLING: {
    PAGE: "Billing",
    DESCRIPTION:
      "Access all the information on your current & previous subscriptions, including plan specifics, usage details, & much more.",
  },

  //---------------- APP SELECTED VOICE AGENT PAGES ----------------
  VA_OVERVIEW: "Overview",
  EDIT_ASSISTANT: "Edit Assistant",
  EDIT_CONTACT: "Contacts",
  EDIT_PROFILE: "Edit Profile",
  EDIT_CAMPAIGN: "Overview",
});

export const SIDER_LIST = [
  {
    icon: <GroupOutlinedIcon />,
    activeIcon: <GroupIcon />,
    appPage: APP_PAGES.ASSISTANTS.PAGE,
    description: APP_PAGES.ASSISTANTS.DESCRIPTION,
    route: ROUTE_PATH.ASSISTANTS,
  },
  // {
  //   icon: <PsychologyOutlinedIcon />,
  //   activeIcon: <PsychologyIcon />,
  //   appPage: APP_PAGES.KNOWLEDGE_BASES.PAGE,
  //   description: APP_PAGES.KNOWLEDGE_BASES.DESCRIPTION,
  //   route: ROUTE_PATH.KNOWLEDGE_BASES,
  // },
  {
    icon: <TtyOutlinedIcon />,
    activeIcon: <TtyIcon />,
    appPage: APP_PAGES.PHONE_NUMBERS.PAGE,
    description: APP_PAGES.PHONE_NUMBERS.DESCRIPTION,
    route: ROUTE_PATH.PHONE_NUMBERS,
  },
  {
    icon: <PermContactCalendarOutlinedIcon />,
    activeIcon: <PermContactCalendarIcon />,
    appPage: APP_PAGES.CONTACT_LIST.PAGE,
    description: APP_PAGES.CONTACT_LIST.DESCRIPTION,
    route: ROUTE_PATH.CONTACT_LIST,
  },
  {
    icon: <CampaignOutlinedIcon />,
    activeIcon: <CampaignIcon />,
    appPage: APP_PAGES.CAMPAIGNS.PAGE,
    description: APP_PAGES.CAMPAIGNS.DESCRIPTION,
    route: ROUTE_PATH.CAMPAIGNS,
  },
  // {
  //   icon: <SettingsOutlinedIcon />,
  //   activeIcon: <SettingsIcon />,
  //   appPage: APP_PAGES.SETTINGS.PAGE,
  //   description: APP_PAGES.SETTINGS.DESCRIPTION,
  //   route: ROUTE_PATH.SETTINGS,
  // },
  {
    icon: <HistoryIcon />,
    activeIcon: <HistoryIcon />,
    appPage: APP_PAGES.CALL_HISTORY.PAGE,
    description: APP_PAGES.CALL_HISTORY.DESCRIPTION,
    route: ROUTE_PATH.CALL_HISTORY,
  },
  {
    icon: <AppsIcon />,
    activeIcon: <AppsIcon />,
    appPage: APP_PAGES.INTEGRATIONS.PAGE,
    description: APP_PAGES.INTEGRATIONS.DESCRIPTION,
    route: ROUTE_PATH.INTEGRATIONS,
  },
  {
    icon: <ApiIcon />,
    activeIcon: <ApiIcon />,
    appPage: APP_PAGES.DEVELOPERSAPI.PAGE,
    description: APP_PAGES.DEVELOPERSAPI.DESCRIPTION,
    route: ROUTE_PATH.DEVELOPERSAPI,
  },
  {
    icon: <SupportAgentIcon />,
    activeIcon: <SupportAgentIcon />,
    appPage: APP_PAGES.SUPPORT.PAGE,
    description: APP_PAGES.SUPPORT.DESCRIPTION,
    route: ROUTE_PATH.SUPPORT,
  },
];
export const INTEGRATIONS_LIST = [
  {
    logo: HUBSPOT_LOGO,
    name: "Hubspot",
    // intergratingApi: installHubSpot
  },
  {
    logo: GOHIGHLEVEL_LOGO,
    name: "GoHighLevel",
    // intergratingApi: installGoHighLevel
  },
];

export const METHOD_TYPES = Object.freeze({
  GET: "GET",
  POST: "POST",
  DELETE: "DELETE",
  PUT: "PUT",
  PATCH: "PATCH",
});

export const MODULE_CONFIGURATIONS = Object.freeze({
  CAMPAIGNS_LOAD_MORE_LIMIT: 10,
  CAMPAIGNS_OVERVIEW_CALL_DATA_LIMIT: 20,
});

export const CAMPAIGN_STATUS = Object.freeze({
  PENDING: "pending",
  IN_PROGRESS: "inProgress",
  STOPPED: "stopped",
  FINISHED: "completed",
  SYSTEM_PAUSED: "systemPaused",
});

export const CAMPAIGN_OUTBOUND_CALL_STATUS = Object.freeze({
  PENDING: "pending",
  DIALED: "dialed",
  IN_QUEUE: "enqueue",
  COMPLETED: "Ended",
  IN_PROGRESS: "inProgress",
  FAILED: "failed",
  STOPPED: "stopped",
});

export const ONBOARDING_STATE = Object.freeze({
  WELCOME: "welcome",
  MICRO_SURVEY: "survey",
  CREATE_ASSISTANT: "createAssistant",
  TEST_ASSISTANT: "testAssistant",
});

export const ASSISTANT_TRAINING_STATUS = Object.freeze({
  IN_QUEUE: "Training In Queue",
  IN_PROGRESS: "In Progress",
  SUCCESS: "Success",
  FAILED: "Failed",
});

export const SUPER_SAVER_DEAL = Object.freeze({
  START_TIME: new Date("24 Nov 2023 16:00:00").getTime(),
  END_TIME: new Date("07 Dec 2023 16:00:00").getTime(),
  PLAN_NAME: "SuperSaver Deal",
});

export const SUBSCRIPTION_STATUS = Object.freeze({
  ACTIVE: "active",
  CANCELED: "canceled",
});

export const CLEVERTAP_EVENT = {
  PAGE_VISIT: "Page Visiting",
  BUTTON_CLICK: "Button Clicked",
  BILLING_VISIT_TYPE: {
    SUBSCRIBE: "Subscription",
  },
  CONTACT_US: "Contact Us",
};

export const SIGNUP_CAROUSEL = [
  {
    IMAGE_PATH: IMG_SIGNUP_1,
  },
  {
    IMAGE_PATH: IMG_SIGNUP_2,
  },
  {
    IMAGE_PATH: IMG_SIGNUP_3,
  },
  {
    IMAGE_PATH: IMG_SIGNUP_4,
  },
];

export const LANG_COUNTRY = {
  en: "US",
  es: "ES",
  fr: "FR",
  de: "DE",
  it: "IT",
  pt: "BR",
  zh: "CN",
  ja: "JP",
  ko: "KR",
  ar: "SA",
  hi: "IN",
  nl: "NL",
  tr: "TR",
  tl: "PH",
  pl: "PL",
  sv: "SE",
  bg: "BG",
  ro: "RO",
  cs: "CZ",
  el: "GR",
  fi: "FI",
  hr: "HR",
  ms: "MY",
  sk: "SK",
  da: "DK",
  ta: "IN",
  uk: "UA",
  ru: "RU",
  vi: "VN",
  no: "NO",
  hu: "HU",
};

export const COUNTRY_NAME_BASED_ON_CODE = {
  US: "American",
  AU: "Australian",
  PT: "Portuguese",
  BR: "Brazilian",
  IN: "Indian",
  ES: "Spanish",
  AR: "Argentinian",
  CO: "Colombian",
  JP: "Japanese",
  AT: "Austrian",
  CH: "Swiss",
  DE: "German",
  FR: "French",
  GB: "British",
  IT: "Italian",
  CN: "Chinese",
  KR: "Korean",
  SA: "Saudi Arabian",
  NL: "Dutch",
  TR: "Turkish",
  PH: "Filipino",
  PL: "Polish",
  SE: "Swedish",
  BG: "Bulgarian",
  RO: "Romanian",
  CZ: "Czech",
  GR: "Greek",
  FI: "Finnish",
  HR: "Croatian",
  MY: "Malaysian",
  SK: "Slovak",
  DK: "Danish",
  UA: "Ukrainian",
  RU: "Russian",
  VN: "Vietnamese",
  NO: "Norwegian",
  HU: "Hungarian",
};

export const LANGUAGES_LIST = [
  { value: "en", label: "English" },
  { value: "es", label: "Spanish" },
  { value: "fr", label: "French" },
  { value: "de", label: "German" },
  { value: "it", label: "Italian" },
  { value: "pt", label: "Portuguese" },
  { value: "zh", label: "Chinese" },
  { value: "ja", label: "Japanese" },
  { value: "ko", label: "Korean" },
  { value: "ar", label: "Arabic" },
  { value: "hi", label: "Hindi" },
  { value: "nl", label: "Dutch" },
  { value: "tr", label: "Turkish" },
  { value: "tl", label: "Filipino" },
  { value: "pl", label: "Polish" },
  { value: "sv", label: "Swedish" },
  { value: "bg", label: "Bulgarian" },
  { value: "ro", label: "Romanian" },
  { value: "cs", label: "Czech" },
  { value: "el", label: "Greek" },
  { value: "fi", label: "Finnish" },
  { value: "hr", label: "Croatian" },
  { value: "ms", label: "Malay" },
  { value: "sk", label: "Slovak" },
  { value: "da", label: "Danish" },
  { value: "ta", label: "Tamil" },
  { value: "uk", label: "Ukrainian" },
  { value: "ru", label: "Russian" },
  { value: "vi", label: "Vietnamese" },
  { value: "no", label: "Norwegian" },
  { value: "hu", label: "Hungarian" },
];

export const MULTI_LANG_CODES = {
  male: [
    {
      label: "Madhur",
      language: ["hi"],
      gender: "male",
      country: "IN",
      vtype: "hi-IN-MadhurNeural",
      tts_provider: "azure",
    },
    {
      label: "Aarav",
      language: ["hi"],
      gender: "male",
      country: "IN",
      vtype: "hi-IN-AaravNeural",
      tts_provider: "azure",
    },
    {
      label: "Kunal",
      language: ["hi"],
      gender: "male",
      country: "IN",
      vtype: "hi-IN-KunalNeural",
      tts_provider: "azure",
    },
    {
      label: "Rehaan",
      language: ["hi"],
      gender: "male",
      country: "IN",
      vtype: "hi-IN-RehaanNeural",
      tts_provider: "azure",
    },
    {
      label: "Steffan",
      language: ["en"],
      gender: "male",
      country: "US",
      vtype: "en-US-SteffanNeural",
      tts_provider: "azure",
    },
    {
      label: "Jason",
      language: ["en"],
      gender: "male",
      country: "US",
      vtype: "en-US-JasonNeural",
      tts_provider: "azure",
    },
    {
      label: "Tony",
      language: ["en"],
      gender: "male",
      country: "US",
      vtype: "en-US-TonyNeural",
      tts_provider: "azure",
    },
    {
      label: "Davis",
      language: ["en"],
      gender: "male",
      country: "US",
      vtype: "en-US-DavisNeural",
      tts_provider: "azure",
    },
    {
      label: "John",
      language: ["en"],
      gender: "male",
      country: "US",
      vtype: "John",
      tts_provider: "azure",
    },
    {
      label: "Donald",
      language: ["en"],
      gender: "male",
      country: "US",
      vtype: "Donald",
      tts_provider: "azure",
    },
    {
      label: "William",
      language: ["en"],
      gender: "male",
      country: "AU",
      vtype: "en-AU-WilliamNeural",
      tts_provider: "azure",
    },
    {
      label: "Darren",
      language: ["en"],
      gender: "male",
      country: "AU",
      vtype: "en-AU-DarrenNeural",
      tts_provider: "azure",
    },
    {
      label: "Ryan",
      language: ["en"],
      gender: "male",
      country: "GB",
      vtype: "en-GB-RyanNeural",
      tts_provider: "azure",
    },
    {
      label: "Alfie",
      language: ["en"],
      gender: "male",
      country: "GB",
      vtype: "en-GB-AlfieNeural",
      tts_provider: "azure",
    },
    //rejected due to robotic presence
    // {
    //   "label": "Elliot",
    //   "lang": "en",
    //   "gender": "male",
    //   "country": "GB",
    //   "vtype": "en-GB-ElliotNeural",
    //   "tts_provider": "azure"
    // },
    {
      label: "Ethan",
      language: ["en"],
      gender: "male",
      country: "GB",
      vtype: "en-GB-EthanNeural",
      tts_provider: "azure",
    },
    {
      label: "Noah",
      language: ["en"],
      gender: "male",
      country: "GB",
      vtype: "en-GB-NoahNeural",
      tts_provider: "azure",
    },
    {
      label: "Oliver",
      language: ["en"],
      gender: "male",
      country: "GB",
      vtype: "en-GB-OliverNeural",
      tts_provider: "azure",
    },
    {
      label: "Thomas",
      language: ["en"],
      gender: "male",
      country: "GB",
      vtype: "en-GB-ThomasNeural",
      tts_provider: "azure",
    },
    //rejected due to robotic presence
    // {
    //   "label": "Ollie",
    //   "lang": "en",
    //   "gender": "male",
    //   "country": "GB",
    //   "vtype": "en-GB-OllieMultilingualNeural",
    //   "tts_provider": "azure"
    // },
    // {
    //   "label": "Duncan",
    //   "lang": "en",
    //   "gender": "male",
    //   "country": "AU",
    //   "speech_rate": 15,
    //   "loudness": 0,
    //   "vtype": "en-AU-DuncanNeural",
    //   "tts_provider": "azure"
    // },
    // {
    //   "label": "Ken",
    //   "lang": "en",
    //   "gender": "male",
    //   "country": "AU",
    //   "speech_rate": 15,
    //   "loudness": 0,
    //   "vtype": "en-AU-KenNeural",
    //   "tts_provider": "azure"
    // },
    // {
    //   "label": "Neil",
    //   "lang": "en",
    //   "gender": "male",
    //   "country": "AU",
    //   "speech_rate": 15,
    //   "loudness": 0,
    //   "vtype": "en-AU-NeilNeural",
    //   "tts_provider": "azure"
    // },
    // {
    //   "label": "Tim",
    //   "lang": "en",
    //   "gender": "male",
    //   "country": "AU",
    //   "speech_rate": 15,
    //   "loudness": 0,
    //   "vtype": "en-AU-TimNeural",
    //   "tts_provider": "azure"
    // },
    {
      label: "Henri",
      language: ["fr"],
      gender: "male",
      country: "FR",
      vtype: "fr-FR-HenriNeural",
      tts_provider: "azure",
    },
    // {
    //   "label": "Alain",
    //   "lang": "fr",
    //   "gender": "male",
    //   "country": "FR",
    //   "vtype": "fr-FR-AlainNeural",
    //   "tts_provider": "azure",
    //   "speech_rate": 15,
    //   "loudness": 0
    // },
    // {
    //   "label": "Claude",
    //   "lang": "fr",
    //   "gender": "male",
    //   "country": "FR",
    //   "vtype": "fr-FR-ClaudeNeural",
    //   "tts_provider": "azure",
    //   "speech_rate": 15,
    //   "loudness": 0
    // },

    // {
    //   "label": "Conrad",
    //   "lang": "de",
    //   "gender": "male",
    //   "country": "DE",
    //   "vtype": "de-DE-ConradNeural1",
    //   "tts_provider": "azure",
    //   "speech_rate": 15,
    //   "loudness": 0
    // },
    {
      label: "Bernd",
      language: ["de"],
      gender: "male",
      country: "DE",
      vtype: "de-DE-BerndNeural",
      tts_provider: "azure",
    },
    // {
    //   "label": "Jonas",
    //   "lang": "de",
    //   "gender": "male",
    //   "country": "AT",
    //   "vtype": "de-AT-JonasNeural",
    //   "tts_provider": "azure",
    //   "speech_rate": 15,
    //   "loudness": 0
    // },
    // {
    //   "label": "Jan",
    //   "lang": "de",
    //   "gender": "male",
    //   "country": "CH",
    //   "vtype": "de-CH-JanNeural",
    //   "tts_provider": "azure",
    //   "speech_rate": 15,
    //   "loudness": 0
    // },

    {
      label: "Alvaro",
      language: ["es"],
      gender: "male",
      country: "ES",
      vtype: "es-ES-AlvaroNeural",
      tts_provider: "azure",
    },
    {
      label: "Arnau",
      language: ["es"],
      gender: "male",
      country: "ES",
      vtype: "es-ES-ArnauNeural",
      tts_provider: "azure",
    },
    // {
    //   "label": "Dario",
    //   "lang": "es",
    //   "gender": "male",
    //   "country": "ES",
    //   "vtype": "es-ES-DarioNeural",
    //   "tts_provider": "azure",
    //   "speech_rate": 15,
    //   "loudness": 0
    // },
    // {
    //   "label": "Elisa",
    //   "lang": "es",
    //   "gender": "male",
    //   "country": "ES",
    //   "vtype": "es-ES-EliasNeural",
    //   "tts_provider": "azure",
    //   "speech_rate": 15,
    //   "loudness": 0
    // },
    // {
    //   "label": "Tomas",
    //   "lang": "es",
    //   "gender": "male",
    //   "country": "AR",
    //   "vtype": "es-AR-TomasNeural",
    //   "tts_provider": "azure",
    //   "speech_rate": 15,
    //   "loudness": 0
    // },
    // {
    //   "label": "Gonzalo",
    //   "lang": "es",
    //   "gender": "male",
    //   "country": "CO",
    //   "vtype": "es-CO-GonzaloNeural",
    //   "tts_provider": "azure",
    //   "speech_rate": 15,
    //   "loudness": 0
    // },
    {
      label: "Duarte",
      language: ["pt"],
      gender: "male",
      country: "PT",
      vtype: "pt-PT-DuarteNeural",
      tts_provider: "azure",
    },
    {
      label: "Antonio",
      language: ["pt"],
      gender: "male",
      country: "BR",
      vtype: "pt-BR-AntonioNeural",
      tts_provider: "azure",
    },
    // {
    //   "label": "Donato",
    //   "lang": "pt",
    //   "gender": "male",
    //   "country": "BR",
    //   "vtype": "pt-BR-DonatoNeural",
    //   "tts_provider": "azure",
    //   "speech_rate": 15,
    //   "loudness": 0
    // },
    // {
    //   "label": "Fabio",
    //   "lang": "pt",
    //   "gender": "male",
    //   "country": "BR",
    //   "vtype": "pt-BR-FabioNeural",
    //   "tts_provider": "azure",
    //   "speech_rate": 15,
    //   "loudness": 0
    // },
    // {
    //   "label": "Humberto",
    //   "lang": "pt",
    //   "gender": "male",
    //   "country": "BR",
    //   "vtype": "pt-BR-HumbertoNeural",
    //   "tts_provider": "azure",
    //   "speech_rate": 15,
    //   "loudness": 0
    // },
    // {
    //   "label": "Julio",
    //   "lang": "pt",
    //   "gender": "male",
    //   "country": "BR",
    //   "vtype": "pt-BR-JulioNeural",
    //   "tts_provider": "azure",
    //   "speech_rate": 15,
    //   "loudness": 0
    // },
    // {
    //   "label": "Nicolau",
    //   "lang": "pt",
    //   "gender": "male",
    //   "country": "BR",
    //   "vtype": "pt-BR-NicolauNeural",
    //   "tts_provider": "azure",
    //   "speech_rate": 15,
    //   "loudness": 0
    // },
    // {
    //   "label": "Valerio",
    //   "lang": "pt",
    //   "gender": "male",
    //   "country": "BR",
    //   "vtype": "pt-BR-ValerioNeural",
    //   "tts_provider": "azure",
    //   "speech_rate": 15,
    //   "loudness": 0
    // },
    // {
    //   "label": "Arnaud",
    //   "lang": "nl",
    //   "gender": "male",
    //   "country": "BE",
    //   "vtype": "nl-BE-ArnaudNeural",
    //   "tts_provider": "azure",
    //   "speech_rate": 15,
    //   "loudness": 0
    // },
    {
      label: "Maarten",
      language: ["nl"],
      gender: "male",
      country: "NL",
      vtype: "nl-NL-MaartenNeural",
      tts_provider: "azure",
    },
    {
      label: "Tamas",
      language: ["hu"],
      gender: "male",
      country: "HU",
      vtype: "hu-HU-TamasNeural",
      tts_provider: "azure",
    },
    {
      label: "Andrew",
      gender: "male",
      language: [
        "en", // English
        "es", // Spanish
        "fr", // French
        "de", // German
        "it", // Italian
        "pt", // Portuguese
        "zh", // Chinese
        "ja", // Japanese
        "ko", // Korean
        "ar", // Arabic
        "hi", // Hindi
        "nl", // Dutch
        "tr", // Turkish
        "tl", // Filipino
        "pl", // Polish
        "sv", // Swedish
        "bg", // Bulgarian
        "ro", // Romanian
        "cs", // Czech
        "el", // Greek
        "fi", // Finnish
        "hr", // Croatian
        "ms", // Malay
        "sk", // Slovak
        "da", // Danish
        "ta", // Tamil
        "uk", // Ukrainian
        "ru", // Russian
        "vi", // Vietnamese
        "no", // Norwegian
        "hu", // Hungarian
      ],
      vtype: "en-US-AndrewMultilingualNeural",
      tts_provider: "azure",
    },
    {
      label: "Brian",
      gender: "male",
      language: [
        "en", // English
        "es", // Spanish
        "fr", // French
        "de", // German
        "it", // Italian
        "pt", // Portuguese
        "zh", // Chinese
        "ja", // Japanese
        "ko", // Korean
        "ar", // Arabic
        "hi", // Hindi
        "nl", // Dutch
        "tr", // Turkish
        "tl", // Filipino
        "pl", // Polish
        "sv", // Swedish
        "bg", // Bulgarian
        "ro", // Romanian
        "cs", // Czech
        "el", // Greek
        "fi", // Finnish
        "hr", // Croatian
        "ms", // Malay
        "sk", // Slovak
        "da", // Danish
        "ta", // Tamil
        "uk", // Ukrainian
        "ru", // Russian
        "vi", // Vietnamese
        "no", // Norwegian
        "hu", // Hungarian
      ],
      vtype: "en-US-BrianMultilingualNeural",
      tts_provider: "azure",
    },
    {
      vtype: "IKne3meq5aSn9XLyUdCD",
      label: "Charlie",
      high_quality_base_model_ids: [
        "eleven_turbo_v2",
        "eleven_multilingual_v2",
        "eleven_turbo_v2_5",
      ],
      language: [
        "en", // English
        "es", // Spanish
        "fr", // French
        "de", // German
        "it", // Italian
        "pt", // Portuguese
        "zh", // Chinese
        "ja", // Japanese
        "ko", // Korean
        "ar", // Arabic
        "hi", // Hindi
        "nl", // Dutch
        "tr", // Turkish
        "tl", // Filipino
        "pl", // Polish
        "sv", // Swedish
        "bg", // Bulgarian
        "ro", // Romanian
        "cs", // Czech
        "el", // Greek
        "fi", // Finnish
        "hr", // Croatian
        "ms", // Malay
        "sk", // Slovak
        "da", // Danish
        "ta", // Tamil
        "uk", // Ukrainian
        "ru", // Russian
        "vi", // Vietnamese
        "no", // Norwegian
        "hu", // Hungarian
      ],
      gender: "male",
      tts_provider: "elevenlabs",
      voice_recording_id: "Charlie",
      preview_url:
        "https://storage.googleapis.com/eleven-public-prod/premade/voices/IKne3meq5aSn9XLyUdCD/102de6f2-22ed-43e0-a1f1-111fa75c5481.mp3",
    },
    {
      vtype: "JBFqnCBsd6RMkjVDRZzb",
      label: "George",
      high_quality_base_model_ids: [
        "eleven_turbo_v2",
        "eleven_multilingual_v2",
        "eleven_turbo_v2_5",
      ],
      language: [
        "en", // English
        "es", // Spanish
        "fr", // French
        "de", // German
        "it", // Italian
        "pt", // Portuguese
        "zh", // Chinese
        "ja", // Japanese
        "ko", // Korean
        "ar", // Arabic
        "hi", // Hindi
        "nl", // Dutch
        "tr", // Turkish
        "tl", // Filipino
        "pl", // Polish
        "sv", // Swedish
        "bg", // Bulgarian
        "ro", // Romanian
        "cs", // Czech
        "el", // Greek
        "fi", // Finnish
        "hr", // Croatian
        "ms", // Malay
        "sk", // Slovak
        "da", // Danish
        "ta", // Tamil
        "uk", // Ukrainian
        "ru", // Russian
        "vi", // Vietnamese
        "no", // Norwegian
        "hu", // Hungarian
      ],
      gender: "male",
      tts_provider: "elevenlabs",
      voice_recording_id: "george",
      preview_url:
        "https://storage.googleapis.com/eleven-public-prod/premade/voices/JBFqnCBsd6RMkjVDRZzb/e6206d1a-0721-4787-aafb-06a6e705cac5.mp3",
    },
    {
      vtype: "N2lVS1w4EtoT3dr4eOWO",
      label: "Callum",
      high_quality_base_model_ids: [
        "eleven_turbo_v2",
        "eleven_multilingual_v2",
        "eleven_turbo_v2_5",
      ],
      language: [
        "en", // English
        "es", // Spanish
        "fr", // French
        "de", // German
        "it", // Italian
        "pt", // Portuguese
        "zh", // Chinese
        "ja", // Japanese
        "ko", // Korean
        "ar", // Arabic
        "hi", // Hindi
        "nl", // Dutch
        "tr", // Turkish
        "tl", // Filipino
        "pl", // Polish
        "sv", // Swedish
        "bg", // Bulgarian
        "ro", // Romanian
        "cs", // Czech
        "el", // Greek
        "fi", // Finnish
        "hr", // Croatian
        "ms", // Malay
        "sk", // Slovak
        "da", // Danish
        "ta", // Tamil
        "uk", // Ukrainian
        "ru", // Russian
        "vi", // Vietnamese
        "no", // Norwegian
        "hu", // Hungarian
      ],
      gender: "male",
      tts_provider: "elevenlabs",
      voice_recording_id: "Callum",
      preview_url:
        "https://storage.googleapis.com/eleven-public-prod/premade/voices/N2lVS1w4EtoT3dr4eOWO/ac833bd8-ffda-4938-9ebc-b0f99ca25481.mp3",
    },
    {
      vtype: "TX3LPaxmHKxFdv7VOQHJ",
      label: "Liam",
      high_quality_base_model_ids: [
        "eleven_turbo_v2",
        "eleven_multilingual_v2",
        "eleven_turbo_v2_5",
      ],
      language: [
        "en", // English
        "es", // Spanish
        "fr", // French
        "de", // German
        "it", // Italian
        "pt", // Portuguese
        "zh", // Chinese
        "ja", // Japanese
        "ko", // Korean
        "ar", // Arabic
        "hi", // Hindi
        "nl", // Dutch
        "tr", // Turkish
        "tl", // Filipino
        "pl", // Polish
        "sv", // Swedish
        "bg", // Bulgarian
        "ro", // Romanian
        "cs", // Czech
        "el", // Greek
        "fi", // Finnish
        "hr", // Croatian
        "ms", // Malay
        "sk", // Slovak
        "da", // Danish
        "ta", // Tamil
        "uk", // Ukrainian
        "ru", // Russian
        "vi", // Vietnamese
        "no", // Norwegian
        "hu", // Hungarian
      ],
      gender: "male",
      tts_provider: "elevenlabs",
      voice_recording_id: "Liam",
      preview_url:
        "https://storage.googleapis.com/eleven-public-prod/premade/voices/TX3LPaxmHKxFdv7VOQHJ/63148076-6363-42db-aea8-31424308b92c.mp3",
    },
    {
      vtype: "bIHbv24MWmeRgasZH58o",
      label: "Will",
      high_quality_base_model_ids: [
        "eleven_turbo_v2",
        "eleven_multilingual_v2",
        "eleven_turbo_v2_5",
      ],
      language: [
        "en", // English
        "es", // Spanish
        "fr", // French
        "de", // German
        "it", // Italian
        "pt", // Portuguese
        "zh", // Chinese
        "ja", // Japanese
        "ko", // Korean
        "ar", // Arabic
        "hi", // Hindi
        "nl", // Dutch
        "tr", // Turkish
        "tl", // Filipino
        "pl", // Polish
        "sv", // Swedish
        "bg", // Bulgarian
        "ro", // Romanian
        "cs", // Czech
        "el", // Greek
        "fi", // Finnish
        "hr", // Croatian
        "ms", // Malay
        "sk", // Slovak
        "da", // Danish
        "ta", // Tamil
        "uk", // Ukrainian
        "ru", // Russian
        "vi", // Vietnamese
        "no", // Norwegian
        "hu", // Hungarian
      ],
      gender: "male",
      tts_provider: "elevenlabs",
      voice_recording_id: "Will",
      preview_url:
        "https://storage.googleapis.com/eleven-public-prod/premade/voices/bIHbv24MWmeRgasZH58o/8caf8f3d-ad29-4980-af41-53f20c72d7a4.mp3",
    },
    {
      vtype: "cjVigY5qzO86Huf0OWal",
      label: "Eric",
      high_quality_base_model_ids: [
        "eleven_turbo_v2",
        "eleven_multilingual_v2",
        "eleven_turbo_v2_5",
      ],
      language: [
        "en", // English
        "es", // Spanish
        "fr", // French
        "de", // German
        "it", // Italian
        "pt", // Portuguese
        "zh", // Chinese
        "ja", // Japanese
        "ko", // Korean
        "ar", // Arabic
        "hi", // Hindi
        "nl", // Dutch
        "tr", // Turkish
        "tl", // Filipino
        "pl", // Polish
        "sv", // Swedish
        "bg", // Bulgarian
        "ro", // Romanian
        "cs", // Czech
        "el", // Greek
        "fi", // Finnish
        "hr", // Croatian
        "ms", // Malay
        "sk", // Slovak
        "da", // Danish
        "ta", // Tamil
        "uk", // Ukrainian
        "ru", // Russian
        "vi", // Vietnamese
        "no", // Norwegian
        "hu", // Hungarian
      ],
      gender: "male",
      tts_provider: "elevenlabs",
      voice_recording_id: "Eric",
      preview_url:
        "https://storage.googleapis.com/eleven-public-prod/premade/voices/cjVigY5qzO86Huf0OWal/d098fda0-6456-4030-b3d8-63aa048c9070.mp3",
    },
    {
      vtype: "iP95p4xoKVk53GoZ742B",
      label: "Chris",
      high_quality_base_model_ids: [
        "eleven_turbo_v2",
        "eleven_multilingual_v2",
        "eleven_turbo_v2_5",
      ],
      language: [
        "en", // English
        "es", // Spanish
        "fr", // French
        "de", // German
        "it", // Italian
        "pt", // Portuguese
        "zh", // Chinese
        "ja", // Japanese
        "ko", // Korean
        "ar", // Arabic
        "hi", // Hindi
        "nl", // Dutch
        "tr", // Turkish
        "tl", // Filipino
        "pl", // Polish
        "sv", // Swedish
        "bg", // Bulgarian
        "ro", // Romanian
        "cs", // Czech
        "el", // Greek
        "fi", // Finnish
        "hr", // Croatian
        "ms", // Malay
        "sk", // Slovak
        "da", // Danish
        "ta", // Tamil
        "uk", // Ukrainian
        "ru", // Russian
        "vi", // Vietnamese
        "no", // Norwegian
        "hu", // Hungarian
      ],
      gender: "male",
      tts_provider: "elevenlabs",
      voice_recording_id: "Chris",
      preview_url:
        "https://storage.googleapis.com/eleven-public-prod/premade/voices/iP95p4xoKVk53GoZ742B/3f4bde72-cc48-40dd-829f-57fbf906f4d7.mp3",
    },
    {
      vtype: "nPczCjzI2devNBz1zQrb",
      label: "Brian",
      high_quality_base_model_ids: [
        "eleven_turbo_v2",
        "eleven_multilingual_v2",
        "eleven_turbo_v2_5",
      ],
      language: [
        "en", // English
        "es", // Spanish
        "fr", // French
        "de", // German
        "it", // Italian
        "pt", // Portuguese
        "zh", // Chinese
        "ja", // Japanese
        "ko", // Korean
        "ar", // Arabic
        "hi", // Hindi
        "nl", // Dutch
        "tr", // Turkish
        "tl", // Filipino
        "pl", // Polish
        "sv", // Swedish
        "bg", // Bulgarian
        "ro", // Romanian
        "cs", // Czech
        "el", // Greek
        "fi", // Finnish
        "hr", // Croatian
        "ms", // Malay
        "sk", // Slovak
        "da", // Danish
        "ta", // Tamil
        "uk", // Ukrainian
        "ru", // Russian
        "vi", // Vietnamese
        "no", // Norwegian
        "hu", // Hungarian
      ],
      gender: "male",
      tts_provider: "elevenlabs",
      voice_recording_id: "Brian",
      preview_url:
        "https://storage.googleapis.com/eleven-public-prod/premade/voices/nPczCjzI2devNBz1zQrb/2dd3e72c-4fd3-42f1-93ea-abc5d4e5aa1d.mp3",
    },
    {
      vtype: "onwK4e9ZLuTAKqWW03F9",
      label: "Daniel",
      high_quality_base_model_ids: [
        "eleven_turbo_v2",
        "eleven_multilingual_v2",
        "eleven_turbo_v2_5",
      ],
      language: [
        "en", // English
        "es", // Spanish
        "fr", // French
        "de", // German
        "it", // Italian
        "pt", // Portuguese
        "zh", // Chinese
        "ja", // Japanese
        "ko", // Korean
        "ar", // Arabic
        "hi", // Hindi
        "nl", // Dutch
        "tr", // Turkish
        "tl", // Filipino
        "pl", // Polish
        "sv", // Swedish
        "bg", // Bulgarian
        "ro", // Romanian
        "cs", // Czech
        "el", // Greek
        "fi", // Finnish
        "hr", // Croatian
        "ms", // Malay
        "sk", // Slovak
        "da", // Danish
        "ta", // Tamil
        "uk", // Ukrainian
        "ru", // Russian
        "vi", // Vietnamese
        "no", // Norwegian
        "hu", // Hungarian
      ],
      gender: "male",
      tts_provider: "elevenlabs",
      voice_recording_id: "Daniel",
      preview_url:
        "https://storage.googleapis.com/eleven-public-prod/premade/voices/onwK4e9ZLuTAKqWW03F9/7eee0236-1a72-4b86-b303-5dcadc007ba9.mp3",
    },
    {
      vtype: "pqHfZKP75CvOlQylNhV4",
      label: "Bill",
      high_quality_base_model_ids: [
        "eleven_turbo_v2",
        "eleven_multilingual_v2",
        "eleven_turbo_v2_5",
      ],
      language: [
        "en", // English
        "es", // Spanish
        "fr", // French
        "de", // German
        "it", // Italian
        "pt", // Portuguese
        "zh", // Chinese
        "ja", // Japanese
        "ko", // Korean
        "ar", // Arabic
        "hi", // Hindi
        "nl", // Dutch
        "tr", // Turkish
        "tl", // Filipino
        "pl", // Polish
        "sv", // Swedish
        "bg", // Bulgarian
        "ro", // Romanian
        "cs", // Czech
        "el", // Greek
        "fi", // Finnish
        "hr", // Croatian
        "ms", // Malay
        "sk", // Slovak
        "da", // Danish
        "ta", // Tamil
        "uk", // Ukrainian
        "ru", // Russian
        "vi", // Vietnamese
        "no", // Norwegian
        "hu", // Hungarian
      ],
      gender: "male",
      tts_provider: "elevenlabs",
      voice_recording_id: "Bill",
      preview_url:
        "https://storage.googleapis.com/eleven-public-prod/premade/voices/pqHfZKP75CvOlQylNhV4/d782b3ff-84ba-4029-848c-acf01285524d.mp3",
    },
  ],
  female: [
    {
      label: "Fenna",
      language: ["nl"],
      gender: "female",
      country: "NL",
      vtype: "nl-NL-FennaNeural",
      tts_provider: "azure",
    },

    {
      label: "Raquel",
      language: ["pt"],
      gender: "female",
      country: "PT",
      vtype: "pt-PT-RaquelNeural",
      tts_provider: "azure",
    },
    {
      label: "Francisca",
      language: ["pt"],
      gender: "female",
      country: "BR",
      vtype: "pt-BR-FranciscaNeural",
      tts_provider: "azure",
    },
    {
      label: "Elvira",
      language: ["es"],
      gender: "female",
      country: "ES",
      vtype: "es-ES-ElviraNeural",
      tts_provider: "azure",
    },
    {
      label: "Abril",
      language: ["es"],
      gender: "female",
      country: "ES",
      vtype: "es-ES-AbrilNeural",
      tts_provider: "azure",
    },
    {
      label: "Katja",
      language: ["de"],
      gender: "female",
      country: "DE",
      vtype: "de-DE-KatjaNeural",
      tts_provider: "azure",
    },
    {
      label: "Amala",
      language: ["de"],
      gender: "female",
      country: "DE",
      vtype: "de-DE-AmalaNeural",
      tts_provider: "azure",
    },
    {
      label: "Denise",
      language: ["fr"],
      gender: "female",
      country: "FR",
      vtype: "fr-FR-DeniseNeural",
      tts_provider: "azure",
    },
    {
      label: "Sara",
      language: ["en"],
      gender: "female",
      country: "US",
      vtype: "en-US-SaraNeural",
      tts_provider: "azure",
    },
    {
      label: "Nancy",
      language: ["en"],
      gender: "female",
      country: "US",
      vtype: "en-US-NancyNeural",
      tts_provider: "azure",
    },
    {
      label: "Aria",
      language: ["en"],
      gender: "female",
      country: "US",
      vtype: "en-US-AriaNeural",
      tts_provider: "azure",
    },
    {
      label: "Karen",
      language: ["en"],
      gender: "female",
      country: "US",
      vtype: "Karen",
      tts_provider: "azure",
    },
    {
      label: "Natasha",
      language: ["en"],
      gender: "female",
      country: "AU",
      vtype: "en-AU-NatashaNeural",
      tts_provider: "azure",
    },
    {
      label: "Annette",
      language: ["en"],
      gender: "female",
      country: "AU",
      vtype: "en-AU-AnnetteNeural",
      tts_provider: "azure",
    },
    {
      label: "Sonia",
      language: ["en"],
      gender: "female",
      country: "GB",
      vtype: "en-GB-SoniaNeural",
      tts_provider: "azure",
    },
    {
      label: "Libby",
      language: ["en"],
      gender: "female",
      country: "GB",
      vtype: "en-GB-LibbyNeural",
      tts_provider: "azure",
    },
    {
      label: "Abbi",
      language: ["en"],
      gender: "female",
      country: "GB",
      vtype: "en-GB-AbbiNeural",
      tts_provider: "azure",
    },
    {
      label: "Ada",
      language: ["en"],
      gender: "female",
      country: "GB",
      vtype: "en-GB-AdaMultilingualNeural",
      tts_provider: "azure",
    },
    {
      label: "Hollie",
      language: ["en"],
      gender: "female",
      country: "GB",
      vtype: "en-GB-HollieNeural",
      tts_provider: "azure",
    },
    {
      label: "Olivia",
      language: ["en"],
      gender: "female",
      country: "GB",
      vtype: "en-GB-OliviaNeural",
      tts_provider: "azure",
    },
    {
      label: "Swara",
      language: ["hi"],
      gender: "female",
      country: "IN",
      vtype: "hi-IN-SwaraNeural",
      tts_provider: "azure",
    },
    {
      label: "Ananya",
      language: ["hi"],
      gender: "female",
      country: "IN",
      vtype: "hi-IN-AnanyaNeural",
      tts_provider: "azure",
    },
    {
      label: "Kavya",
      language: ["hi"],
      gender: "female",
      country: "IN",
      vtype: "hi-IN-KavyaNeural",
      tts_provider: "azure",
    },
    {
      label: "Ava",
      gender: "female",
      vtype: "en-US-AvaMultilingualNeural",
      tts_provider: "azure",
    },
    {
      label: "Emma",
      gender: "female",
      vtype: "en-US-EmmaMultilingualNeural",
      tts_provider: "azure",
    },
    {
      label: "Noemi",
      language: ["hu"],
      gender: "female",
      country: "HU",
      vtype: "hu-HU-NoemiNeural",
      tts_provider: "azure",
    },
    {
      vtype: "EXAVITQu4vr4xnSDxMaL",
      label: "Sarah",
      high_quality_base_model_ids: [
        "eleven_turbo_v2",
        "eleven_multilingual_v2",
        "eleven_turbo_v2_5",
      ],
      language: [
        "en", // English
        "es", // Spanish
        "fr", // French
        "de", // German
        "it", // Italian
        "pt", // Portuguese
        "zh", // Chinese
        "ja", // Japanese
        "ko", // Korean
        "ar", // Arabic
        "hi", // Hindi
        "nl", // Dutch
        "tr", // Turkish
        "tl", // Filipino
        "pl", // Polish
        "sv", // Swedish
        "bg", // Bulgarian
        "ro", // Romanian
        "cs", // Czech
        "el", // Greek
        "fi", // Finnish
        "hr", // Croatian
        "ms", // Malay
        "sk", // Slovak
        "da", // Danish
        "ta", // Tamil
        "uk", // Ukrainian
        "ru", // Russian
        "vi", // Vietnamese
        "no", // Norwegian
        "hu", // Hungarian
      ],
      gender: "female",
      tts_provider: "elevenlabs",
      voice_recording_id: "Sarah",
      preview_url:
        "https://storage.googleapis.com/eleven-public-prod/premade/voices/EXAVITQu4vr4xnSDxMaL/01a3e33c-6e99-4ee7-8543-ff2216a32186.mp3",
    },
    {
      vtype: "FGY2WhTYpPnrIDTdsKH5",
      label: "Laura",
      high_quality_base_model_ids: [
        "eleven_turbo_v2",
        "eleven_multilingual_v2",
        "eleven_turbo_v2_5",
      ],
      language: [
        "en", // English
        "es", // Spanish
        "fr", // French
        "de", // German
        "it", // Italian
        "pt", // Portuguese
        "zh", // Chinese
        "ja", // Japanese
        "ko", // Korean
        "ar", // Arabic
        "hi", // Hindi
        "nl", // Dutch
        "tr", // Turkish
        "tl", // Filipino
        "pl", // Polish
        "sv", // Swedish
        "bg", // Bulgarian
        "ro", // Romanian
        "cs", // Czech
        "el", // Greek
        "fi", // Finnish
        "hr", // Croatian
        "ms", // Malay
        "sk", // Slovak
        "da", // Danish
        "ta", // Tamil
        "uk", // Ukrainian
        "ru", // Russian
        "vi", // Vietnamese
        "no", // Norwegian
        "hu", // Hungarian
      ],
      gender: "female",
      tts_provider: "elevenlabs",
      voice_recording_id: "Laura",
      preview_url:
        "https://storage.googleapis.com/eleven-public-prod/premade/voices/FGY2WhTYpPnrIDTdsKH5/67341759-ad08-41a5-be6e-de12fe448618.mp3",
    },
    {
      vtype: "XB0fDUnXU5powFXDhCwa",
      label: "Charlotte",
      high_quality_base_model_ids: [
        "eleven_turbo_v2",
        "eleven_multilingual_v2",
        "eleven_turbo_v2_5",
      ],
      language: [
        "en", // English
        "es", // Spanish
        "fr", // French
        "de", // German
        "it", // Italian
        "pt", // Portuguese
        "zh", // Chinese
        "ja", // Japanese
        "ko", // Korean
        "ar", // Arabic
        "hi", // Hindi
        "nl", // Dutch
        "tr", // Turkish
        "tl", // Filipino
        "pl", // Polish
        "sv", // Swedish
        "bg", // Bulgarian
        "ro", // Romanian
        "cs", // Czech
        "el", // Greek
        "fi", // Finnish
        "hr", // Croatian
        "ms", // Malay
        "sk", // Slovak
        "da", // Danish
        "ta", // Tamil
        "uk", // Ukrainian
        "ru", // Russian
        "vi", // Vietnamese
        "no", // Norwegian
        "hu", // Hungarian
      ],
      gender: "female",
      tts_provider: "elevenlabs",
      voice_recording_id: "Charlotte",
      preview_url:
        "https://storage.googleapis.com/eleven-public-prod/premade/voices/XB0fDUnXU5powFXDhCwa/942356dc-f10d-4d89-bda5-4f8505ee038b.mp3",
    },
    {
      vtype: "Xb7hH8MSUJpSbSDYk0k2",
      label: "Alice",
      high_quality_base_model_ids: [
        "eleven_turbo_v2",
        "eleven_multilingual_v2",
        "eleven_turbo_v2_5",
      ],
      language: [
        "en", // English
        "es", // Spanish
        "fr", // French
        "de", // German
        "it", // Italian
        "pt", // Portuguese
        "zh", // Chinese
        "ja", // Japanese
        "ko", // Korean
        "ar", // Arabic
        "hi", // Hindi
        "nl", // Dutch
        "tr", // Turkish
        "tl", // Filipino
        "pl", // Polish
        "sv", // Swedish
        "bg", // Bulgarian
        "ro", // Romanian
        "cs", // Czech
        "el", // Greek
        "fi", // Finnish
        "hr", // Croatian
        "ms", // Malay
        "sk", // Slovak
        "da", // Danish
        "ta", // Tamil
        "uk", // Ukrainian
        "ru", // Russian
        "vi", // Vietnamese
        "no", // Norwegian
        "hu", // Hungarian
      ],
      gender: "female",
      tts_provider: "elevenlabs",
      voice_recording_id: "Alice",
      preview_url:
        "https://storage.googleapis.com/eleven-public-prod/premade/voices/Xb7hH8MSUJpSbSDYk0k2/d10f7534-11f6-41fe-a012-2de1e482d336.mp3",
    },
    {
      vtype: "XrExE9yKIg1WjnnlVkGX",
      label: "Matilda",
      high_quality_base_model_ids: [
        "eleven_turbo_v2",
        "eleven_multilingual_v2",
        "eleven_turbo_v2_5",
      ],
      language: [
        "en", // English
        "es", // Spanish
        "fr", // French
        "de", // German
        "it", // Italian
        "pt", // Portuguese
        "zh", // Chinese
        "ja", // Japanese
        "ko", // Korean
        "ar", // Arabic
        "hi", // Hindi
        "nl", // Dutch
        "tr", // Turkish
        "tl", // Filipino
        "pl", // Polish
        "sv", // Swedish
        "bg", // Bulgarian
        "ro", // Romanian
        "cs", // Czech
        "el", // Greek
        "fi", // Finnish
        "hr", // Croatian
        "ms", // Malay
        "sk", // Slovak
        "da", // Danish
        "ta", // Tamil
        "uk", // Ukrainian
        "ru", // Russian
        "vi", // Vietnamese
        "no", // Norwegian
        "hu", // Hungarian
      ],
      gender: "female",
      tts_provider: "elevenlabs",
      voice_recording_id: "matilda",
      preview_url:
        "https://storage.googleapis.com/eleven-public-prod/premade/voices/XrExE9yKIg1WjnnlVkGX/b930e18d-6b4d-466e-bab2-0ae97c6d8535.mp3",
    },
    {
      vtype: "cgSgspJ2msm6clMCkdW9",
      label: "Jessica",
      high_quality_base_model_ids: [
        "eleven_turbo_v2",
        "eleven_multilingual_v2",
        "eleven_turbo_v2_5",
      ],
      language: [
        "en", // English
        "es", // Spanish
        "fr", // French
        "de", // German
        "it", // Italian
        "pt", // Portuguese
        "zh", // Chinese
        "ja", // Japanese
        "ko", // Korean
        "ar", // Arabic
        "hi", // Hindi
        "nl", // Dutch
        "tr", // Turkish
        "tl", // Filipino
        "pl", // Polish
        "sv", // Swedish
        "bg", // Bulgarian
        "ro", // Romanian
        "cs", // Czech
        "el", // Greek
        "fi", // Finnish
        "hr", // Croatian
        "ms", // Malay
        "sk", // Slovak
        "da", // Danish
        "ta", // Tamil
        "uk", // Ukrainian
        "ru", // Russian
        "vi", // Vietnamese
        "no", // Norwegian
        "hu", // Hungarian
      ],
      gender: "female",
      tts_provider: "elevenlabs",
      voice_recording_id: "jessica",
      preview_url:
        "https://storage.googleapis.com/eleven-public-prod/premade/voices/cgSgspJ2msm6clMCkdW9/56a97bf8-b69b-448f-846c-c3a11683d45a.mp3",
    },
    {
      vtype: "pFZP5JQG7iQjIQuC4Bku",
      label: "Lily",
      high_quality_base_model_ids: [
        "eleven_turbo_v2",
        "eleven_multilingual_v2",
        "eleven_turbo_v2_5",
      ],
      language: [
        "en", // English
        "es", // Spanish
        "fr", // French
        "de", // German
        "it", // Italian
        "pt", // Portuguese
        "zh", // Chinese
        "ja", // Japanese
        "ko", // Korean
        "ar", // Arabic
        "hi", // Hindi
        "nl", // Dutch
        "tr", // Turkish
        "tl", // Filipino
        "pl", // Polish
        "sv", // Swedish
        "bg", // Bulgarian
        "ro", // Romanian
        "cs", // Czech
        "el", // Greek
        "fi", // Finnish
        "hr", // Croatian
        "ms", // Malay
        "sk", // Slovak
        "da", // Danish
        "ta", // Tamil
        "uk", // Ukrainian
        "ru", // Russian
        "vi", // Vietnamese
        "no", // Norwegian
        "hu", // Hungarian
      ],
      gender: "female",
      tts_provider: "elevenlabs",
      voice_recording_id: "Lily",
      preview_url:
        "https://storage.googleapis.com/eleven-public-prod/premade/voices/pFZP5JQG7iQjIQuC4Bku/89b68b35-b3dd-4348-a84a-a3c13a3c2b30.mp3",
    },
  ],
};

export const PAGE_STEPS = {
  phoneNumberTwilio: {
    title: "Follow these steps to buy your Twilio Phone Number",
    steps: [
      "Click on 'Go To Twilio' button. Sign up your Twilio account.",
      'Go to "Phone Numbers" in the left panel.',
      'Select "Manage" then "Buy a Number".',
      'Choose any number of your choice and click on "Buy". Now Agree with the T&C and click on Buy.',
      "Click on your account name (top left), then scroll down to view your Account SID and Auth Token.",
      'To add this number to VoiceGenie, click on "Add Phone Number" button above & enter the details and Click on "Save".',
    ],
  },
  phoneNumberPlivo: {
    title: "Follow these steps to buy your Plivo Phone Number",
    steps: [
      "Click on 'Go To Plivo' button. Sign up to your Plivo account. Select 'Phone Numbers' in the left panel. Click on 'Buy Number'",
      "Fill in the details and click on 'Search'. Select any number of your choice & click on 'Buy Number & Continue'. Enter details & click on 'Configure Number'",
      "Once configured, you can access your new phone number. You can easily find the Auth ID & Auth Token in the Overview Section of Plivo",
      "To add this number to VoiceGenie, click on 'Add Phone Number' button above & enter the details and Click on 'Save'",
    ],
  },
};

export const SCRIPT_TEMPLATES = {
  "existing script": `[Wait for the user response] 
  
  Understanding Needs: Dive into a conversation about what they're looking for in a Tesla vehicle. 
  
  Use open-ended questions to gauge their preferences, such as, "What features are you looking for in a car?" or "What's most important to you in a vehicle?
  
  "Once User tells the requirement, Model Suggestion: Based on their requirements, suggest the most suitable Tesla model. Keep your explanation brief but informative.
  
  Encourage Test Drive: If the lead shows interest, encourage them to book a test drive. 
  
  Tell them, "Great choice! Would you like to experience the Model firsthand with a test drive?
  
  "If user agrees, Information Verification: If a user is willing to book a test drive, politely confirm the customer's details by stating the information you have and asking them to verify it. 
  
  For example, "I have your name as Nester, email address as abc@gmail.com and your contact number as 6465685669, is that correct?
  
  "Once User gives personal information,Booking Forwarding: Inform the customer that a Tesla representative will contact them to finalize the test drive details. 
  
  You might say, "I'm excited you're interested in a test drive. A Tesla representative will reach out shortly to confirm your booking details.
  
  "Conversation Closure:End the call on a positive note, expressing appreciation for their time and interest.`,
  current: ``,
};

export const EXISTING_SCRIPT_CALL_OPENING_MESSAGE = `<p>Hi <span id="ts-mention-blot" class="ts-mention" data-name="first_name" data-id="1234">﻿<span contenteditable="false">first_name</span>﻿</span>, This is James, calling from Tesla. How are you doing today?</p>`;
export const EXISTING_SCRIPT_GOAL =
  "To convince users to book a test ride and give all their queries about Tesla.";

export const PlatformIntegrationStepsData = [
  {
    title: "Sign Up",
    key: "signedUp",
  },
  {
    title: "Creating First Assistant",
    button: "Create First AI Assistant",
    route: ROUTE_PATH.ASSISTANTS,
    howTo:
      "https://voicegenie.gitbook.io/voicegenie-ai/product-guides/assistants",
    key: "everCreatedAssistant",
  },
  {
    title: "Providing Phone Number",
    button: "Add First Phone Number",
    route: ROUTE_PATH.PHONE_NUMBERS,
    howTo:
      "https://voicegenie.gitbook.io/voicegenie-ai/product-guides/phone-numbers",
    key: "everCreatedPhoneNumber",
  },
  {
    title: "Adding Contact List",
    button: "Create First Contact",
    route: ROUTE_PATH.CONTACT_LIST,
    howTo:
      "https://voicegenie.gitbook.io/voicegenie-ai/product-guides/contact-list",
    key: "everCreatedContactList",
  },
  {
    title: "Running your First Campaign!",
    button: "Run Your First Campaign",
    route: ROUTE_PATH.CAMPAIGNS,
    howTo:
      "https://voicegenie.gitbook.io/voicegenie-ai/product-guides/campaigns",
    key: "everStartedCampaign",
  },
];

export const TOGGLE_BUTTON = {
  toggleButtonDefault: "Twilio",
  toggleButtonArray: ["Twilio", "Plivo"],
};

export const languageModelMap = {
  eleven_multilingual_v2: [
    "en", // English
    "es", // Spanish
    "fr", // French
    "de", // German
    "it", // Italian
    "pt", // Portuguese
    "zh", // Chinese
    "ja", // Japanese
    "ko", // Korean
    "ar", // Arabic
    "hi", // Hindi
    "nl", // Dutch
    "tr", // Turkish
    "tl", // Filipino
    "pl", // Polish
    "sv", // Swedish
    "bg", // Bulgarian
    "ro", // Romanian
    "cs", // Czech
    "el", // Greek
    "fi", // Finnish
    "hr", // Croatian
    "ms", // Malay
    "sk", // Slovak
    "da", // Danish
    "ta", // Tamil
    "uk", // Ukrainian
    "ru", // Russian
  ],
  eleven_turbo_v2_5: [
    "en", // English
    "es", // Spanish
    "fr", // French
    "de", // German
    "it", // Italian
    "pt", // Portuguese
    "zh", // Chinese
    "ja", // Japanese
    "ko", // Korean
    "ar", // Arabic
    "hi", // Hindi
    "nl", // Dutch
    "tr", // Turkish
    "tl", // Filipino
    "pl", // Polish
    "sv", // Swedish
    "bg", // Bulgarian
    "ro", // Romanian
    "cs", // Czech
    "el", // Greek
    "fi", // Finnish
    "hr", // Croatian
    "ms", // Malay
    "sk", // Slovak
    "da", // Danish
    "ta", // Tamil
    "uk", // Ukrainian
    "vi", // Vietnamese
    "no", // Norwegian
    "hu", // Hungarian
  ],
  eleven_turbo_v2: [
    "en", // English
  ],
};
