import React, { useEffect, useState } from "react";
import {
  Button,
  TextField,
  Grid,
  Box,
  Typography,
  CircularProgress,
} from "@mui/material";
import useAudioStreamElevenLabsAudioSamples from "../../../../data/hooks/useAudioStreamElevenLabsAudioSamples";
import { useSelector } from "react-redux";
import CustomHintPopover from "../../../CustomHintPopover";

const GenerateElevenLabsAudioSample = ({ updatedData, setSampleAudioUrl }) => {
  const token = useSelector((state) => state.authDetails.token);

  const [text, setText] = useState("");
  const [showTextBox, setShowTextBox] = useState(false);
  const [payload, setPayload] = useState(null);

  // Hook to fetch and stream audio
  const { audioUrl, error, loading, fetchAudio } =
    useAudioStreamElevenLabsAudioSamples(payload, token);

  useEffect(() => {
    if (audioUrl) {
      setSampleAudioUrl(audioUrl);
    }
  }, [audioUrl, setSampleAudioUrl]);

  useEffect(() => {
    if (payload) {
      fetchAudio(); // Trigger the audio fetching process when payload changes
    }
  }, [payload, fetchAudio]);

  const handleGenerateClick = () => {
    setShowTextBox((prev) => !prev);
    setText("");
  };

  const handleTextChange = (event) => {
    setText(event.target.value);
  };

  const handleGenerateAudio = () => {
    // Ensure text is not empty and updatedData values are present
    if (text.trim() && updatedData?.vtype && updatedData?.model) {
      setPayload({
        voice_id: updatedData.vtype,
        model_id: updatedData.model ?? "eleven_multilingual_v2",
        user_text: text.trim() || "",
      });
    }
  };

  return (
    <Grid container spacing={2} mt={2} alignItems="center">
      {!showTextBox ? (
        <Grid item>
          <Button variant="contained" onClick={handleGenerateClick}>
            Generate Audio Sample
          </Button>
        </Grid>
      ) : (
        <Grid
          container
          xs={12}
          item
          display="flex"
          justifyContent="center"
          alignItems="center"
        >
          <Grid item xs={12}>
            <TextField
              size="small"
              label="Enter Text"
              value={text}
              multiline
              onChange={handleTextChange}
              fullWidth
            />
          </Grid>
          <Grid
            item
            xs={12}
            display="flex"
            justifyContent="flex-end"
            alignItems="center"
            gap={2}
            marginBlock={2}
          >
            <Button
              size="small"
              variant="contained"
              onClick={handleGenerateAudio}
            >
              Generate
            </Button>
            <Button
              size="small"
              variant="outlined"
              onClick={handleGenerateClick}
            >
              Close
            </Button>
          </Grid>
        </Grid>
      )}

      {loading && (
        <Grid item>
          <Box mt={2}>
            <CircularProgress />
          </Box>
        </Grid>
      )}

      {error && (
        <Grid item>
          <Box mt={2} color="red">
            <Typography variant="body1">Error: {error}</Typography>
          </Box>
        </Grid>
      )}
    </Grid>
  );
};

export default GenerateElevenLabsAudioSample;
